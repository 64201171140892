@import "../../Static/scss/colours";
@import "../../Static/scss/mixin";

.optionsMaster{
    height: 100%;
    width: 100%;
    color: #100F0D;
    padding: 25px 25px 0 25px;
    .headersection{
        height: 150px;
        width: 90%;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-items: center;
        background-color: white;
        .maintitle{
            font-weight: 600;
            font-size: 25px;
            line-height: 30px;
        }
        .maindesc{
            font-weight: 300;
            font-size: 12px;
            line-height: 27px;
        }
    }
    .cardsSection{

        height: 95%;
        width: 100%;
        overflow-y: scroll;
        margin-top: 10px;

        display: flex;
        flex-direction: column;
        align-items: center;
        .eachcard{
            cursor: pointer;
            height: 170px;
            width: 90%;
            margin: 20px 0;
            padding: 30px;
            border: double 9px transparent;
            border-width: 1px 1px 1px 9px;
            border-radius: 15px;
            background-image: linear-gradient(white, white),
    // radial-gradient(circle at center, #5E3199 , #B580F2);
            linear-gradient(to bottom,#5E3199 , #B580F2);
            background-origin: border-box;
            background-clip: padding-box, border-box;
            border-top: 1px solid #e7e7e7;
            border-right: 1px solid #e7e7e7;
            border-bottom: 1px solid #e7e7e7;
            opacity: 0.5;
            .queryTitle{
              font-weight: 600;
              font-size: 20px;
              line-height: 24px;
            }
            .queryDesc{
              font-weight: 300;
              font-size: 12px;
              line-height: 27px;
            }
          }
          .eachcard:hover{
            // background-color: #f5f5f5;
            transform: scale(1.05);
            transition: .6s ease;
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            opacity: 1;
          }
          .selectactive{
            // background-color: #f5f5f5;
            transform: scale(1.05);
            transition: .6s ease;
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            opacity: 1;
          }
    }
}

// media query 
@media only screen and (max-width: 600px) {
    .optionsMaster{
        .headersection{
            height: 100px;
            width: 90%;
            padding: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            align-items: center;
            background-color: white;
            .maintitle{
                font-weight: 600;
                font-size: 25px;
                line-height: 30px;
            }
            .maindesc{
                font-weight: 300;
                font-size: 12px;
                line-height: 27px;
            }
        }
        .cardsSection{
    
            height: calc(100% - 190px);
            width: 100%;
            overflow-y: scroll;
            margin-top: 20px;
            // display: flex;
            // flex-direction: column;
            // align-items: center;
            .eachcard{
                cursor: pointer;
                height: 170px;
                width: 90%;
                margin: 20px 0;
                padding: 30px;
                border: double 9px transparent;
                border-width: 1px 1px 1px 9px;
                border-radius: 15px;
                background-image: linear-gradient(white, white),
        // radial-gradient(circle at center, #5E3199 , #B580F2);
                linear-gradient(to bottom,#5E3199 , #B580F2);
                background-origin: border-box;
                background-clip: padding-box, border-box;
                border-top: 1px solid #e7e7e7;
                border-right: 1px solid #e7e7e7;
                border-bottom: 1px solid #e7e7e7;
                opacity: 0.5;
                .queryTitle{
                  font-weight: 600;
                  font-size: 20px;
                  line-height: 24px;
                }
                .queryDesc{
                  font-weight: 300;
                  font-size: 12px;
                  line-height: 27px;
                }
              }
              .eachcard:hover{
                // background-color: #f5f5f5;
                transform: scale(1.05);
                transition: .6s ease;
                box-shadow: rgba(149,
                157, 165, 0.2) 0px 8px 24px;
                opacity: 1;
              }
              .selectactive{
                // background-color: #f5f5f5;
                transform: scale(1.05);
                transition: .6s ease;
                box-shadow: rgba(149,
                157, 165, 0.2) 0px 8px 24px;
                opacity: 1;
              }
            }
          }
        }