.mainoffer
{
.block-heading{
    font-family: HelveticaNeue;
    font-size: 20px;
    font-weight: bold;
    color: #100F0D;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 6.2%;
    padding-right: 6.2%;
    margin-top: 3rem;
    margin-bottom: 2rem;
    &::after{
        background: #EBEBEB;
        height: 0.5px;
        flex: 1;
        content: '';
        margin-left: 24px;
    }
}

.bankoffer
{display: flex;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-left: 6.2%;
    padding-right: 6.2%;
    .sectleft{
        width:32%;
.lefside{
    background: #0000ff0a;

    p{
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.2px;
        color: #4a4a4a;
        text-align: left;
        padding-top: 10px;
        margin: 0px 57px 10px 0px;
        padding-left: 2.5%;
    }
    .sub{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-evenly;
        height: 13rem;
        /* padding-top: 10px; */
        align-items: center;

        .sec{
            width: 30%;
    height: 60px;
    border: solid 1px #d9e0e2;
    background-color: #ffffff;
    display: flex;
align-items: center;
justify-content: center;
    cursor: pointer;
    &:hover{
        transform: scale(1.03);
    }
img{
    width: 4rem;
}
        }
    }
}
}
    .seconright{
        width: 32%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    
        .sub{
            display: flex;
            justify-content: space-between;
            height: 50%;
            img{
                width: 49%;
                &:hover{
                    transform: scale(1.01);
                }
            }
        }

    }
    .righside{
        width: 32%;
        img{
            width: 100%;
            &:hover{
                transform: scale(1.01);
            }
        }
        .below{
            display: flex;
            display: flex;
            margin-top: 15px;
            margin-bottom: 15px;
            justify-content: space-between;
            img{
                width: 49%;
                &:hover{
                    transform: scale(1.01);
                }
            }
        }
    }  
}
}
.LoanMainSection{
    width: 100%;
    height: 31rem;
    padding-left: 6.2%;
    padding-right: 6.2%;
    margin-top: 3rem;
    margin-bottom: 3rem;
    .cusom{
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 2rem;
        margin-bottom: 3rem;
        img{
            width: 32%;
        }
    }
    .block-heading{
  
        font-size: 20px;
        font-weight: bold;
        color: #100F0D;
        display: flex;
        align-items: center;
        justify-content: center;
        &::after{
            background: #EBEBEB;
            height: 0.5px;
    flex: 1;
    content: '';
    margin-left: 24px;

        }
    }
 .loansub{
     background-repeat: no-repeat;
     background-size: 100% 100%;
     width: 100%;
     height: 100%;
 }   
}


 .site-links-wrapper {
    background-color: #635c5a;
    color: #ffffff;
    padding-bottom: 20px;
    height: 7rem;
    margin-top: 5rem;
    margin-bottom: 83px;
    .content-wrapper {
        padding-left: 6.2%;
        padding-right: 6.2%;
        display: flex;
        justify-content: space-between;
    
        width: 100%;

       .site-block {
width: 25%;
            padding-top: 41px;
            display: inline-block;
            vertical-align: top;
      .links-header {
                font-size: 15px;
                font-weight: 600;
                letter-spacing: 1px;
            }

             ul {
                padding: 0;
                 li {
                    cursor: pointer;
                    list-style: none;
                    transition: all .3s;
                    a {
                        color: rgba(255,255,255,0.8);
                    }
                }
            }
        }
    }
}


 .footer-toolbar-wrapper {
    background-color: #3d3a38;
    color: #ffffff;
    padding-top: 40px;
    padding-bottom: 30px;

 .right-side-wrapper {
        display: inline-block;
        vertical-align: top;
   
            display: inline-block;
            vertical-align: top;
           .right-container {
                display: inline-block;
                width: 350px;
                margin-left: 20px;
                vertical-align: top;
                .hiring-wrapper {
 .hiring-see-all {
                        margin-top: 20px;
                    }
   .hiring-desc {
                        width: 297px;
                        overflow: hidden;
                        max-height: 100px;
                        line-height: 1.45em;
                        max-height: 7.25em;
                        position: relative;
                    }
                .hiring-header {
                    font-size: 20px;
                    padding-bottom: 15px;
                }
            
            }
            }
             .bottom-level-wrapper {
                margin-top: 20px;
                margin-left: 20px;
                padding-top: 20px;
                border-top: 1px solid #100F0D;

                position: relative;
                .subscribe-wrapper {
                    display: inline-block;
                    vertical-align: top;
                    padding-left: 20px;
          .footer-sub-alert {
                        margin-top: 5px;
                        font-style: italic;
                        color: rgba(255,255,255,0.7);
                        width: 360px;
                    }
                    .subscribe-header {
                        font-size: 20px;
                        padding-bottom: 10px;
                    }
       .subscribe-desc {
                        color: rgba(255,255,255,0.7);
                        width: 319px;
                    }
                 .subscribe-btn-holder {
                        padding-top: 10px;
                    }
                }
            .blog-wrapper {
                    width: 360px;
                    display: inline-block;
                    vertical-align: top;
                    .desc-wrapper {
                        display: inline-block;
                        vertical-align: top;
                    }
               .blog-img {
                        width: 60px;
                        height: 60px;
                        display: inline-block;
                        overflow: hidden;
                        vertical-align: top;
                        margin-top: 6px;
                    }
                   .blog-header {
                        font-size: 20px;
                        padding-bottom: 12px;
                    }
                }
            }
        
        .download-app-wrapper {
            width: 360px;
            height: 243px;
            background: #050000;
            border-radius: 2px;
            display: inline-block;
            margin-left: 20px;
            .download-header {
                font-size: 20px;
                padding-left: 20px;
                padding-top: 25px;
            }
        }
    }

    .content-wrapper {
        padding-left: 6.2%;
        padding-right: 6.2%;
        display: flex;
        justify-content: space-between;
    
        width: 100%;
        width: 100%;
        &::after{
      
                clear: both;
                display: table;
                content: " ";
            
        }
        .toolbar-ext {
            width: 380px;
            height: 492px;
            background-color: #ffd050;
            display: inline-block;
            text-align: center;
            .toolbar-download {
                width: 380px;
                height: 70px;
                background-color: #cd3232;
                line-height: 63px;
                text-align: center;
                font-size: 21px;
                font-weight: bold;
                font-style: normal;
                font-stretch: normal;
                letter-spacing: 1px;
                color: #ffffff;
            }
            .toolbar-img {
                display: inline-block;
                vertical-align: top;
            }
            .footer-top-offer {
                width: 60px;
                height: 36px;
                background-position: -75px -5px;
                margin-left: 38px;
            }
       .footer-special-offer {
                margin-left: 44px;
               
                    height: 35px;
                    width: 60px;
                    margin-left: 38px;
                    background-position: -5px -4px;
                    vertical-align: baseline;
            }
            .divider {
                margin-left: 128px;
                width: 210px;
                height: 0px;
                opacity: 0.12;
                border-bottom: solid 1px #000000;
            }
   .toolbar-img-text {
                width: 191px;
                height: 42px;
                font-size: 18px;
                font-weight: bold;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.17;
                letter-spacing: normal;
                text-align: left;
                color: #cd3232;
                display: inline-block;
                margin-left: 27px;
                padding: 5px 0;
                box-sizing: border-box;
                 div {
                    font-size: 14px;
                    font-weight: normal;
                    line-height: 1.5;
                    color: #8a6500;
                }
            }
       
            .footer-cashback-coupons {
                height: 60px;
                width: 60px;
                margin-left: 38px;
                background-position: -145px -5px;
            }
            .extension-main-sprite {
               
                display: inline-block;
            }
        .ext-benefits {
                margin: 16px 0;
            }
            .toolbar-img-holder {
                text-align: left;
            }
            .toolbar-desc {
                color: #a27600;
                text-align: left;
                padding: 25px 17px 4px 27px;
              div {
                    margin-bottom: 10px;
                }
            }

            .toolbar-header {
                padding: 13px;
                font-weight: 500;
                background-color: #f0b003;
                .install-text-container {
                    display: inline-block;
                    text-align: left;
                    padding-left: 10px;
                    width: 287px;
                    vertical-align: top;
                    color: black;
                    .install-text-header {
                        font-size: 17px;
                    }
                  .install-text {
                        display: inline-block;
                        vertical-align: top;
                        font-size: 27px;
                        font-weight: bold;
                        width: 276px;
                        line-height: 25px;
                        text-align: left;
                        color: #0a0a0a;
                        box-sizing: border-box;
                    }
                }
             
                .ext-cd-logo {
                    display: inline-block;
                    margin: 0px;
                    width: 57px;
                    height: 58px;
                    background-position: -364px -50px;
                }
            }
        }
    }
}