.react-multiple-carousel__arrow{
    display: none;
 
}
.mainclass{
    border: 0.5px solid #EBEBEB; 

    height: 13rem;
    padding-top: 13px;
    margin-left: 5%; 
    margin-bottom: 2rem;
    margin-top: 2rem;
    margin-right: 5%;
    .logo-mobile{
        width: 12rem;
            position: relative;
            top: 13px;
            padding-left: 8%;
          
    }
    .doubletab{
        display: flex;
        height: 82%;
        align-items: center;
    }
    .subimagetag{
        background-position: top;
        height: 100%;
        width: 50%;
        background-size: contain;
        background-repeat: no-repeat;
    
    }
    
    .divlab{
        width: 50%;
        display: flex;
        align-items: center;
        margin: auto;
        text-align: center;
        justify-content: center;
        label{
            border: 0.5px solid #EBEBEB;
            width: 7rem;
            height: 2.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;
        &:hover{
        transform: scale(1.03);
        }
        }
        img{
            width: 4rem;
        }
    }
}
.footerend
{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 6.2%;
    padding-left: 6.2%;
    border-top: 1px solid #EBEBEB;
    margin-top: 4rem;
    height: 6rem;
    .subk{
        display: flex;
    align-items: center;
    justify-content: space-between;
    width: 11rem;
    img{
        cursor: pointer;
        &:hover{
            transform: scale(1.03);
        }
    }
    }
}


.carsoulesection
{
    width: 100%;
    
    display: none;
    .react-multi-carousel-list{
height: 100%;
width: 100%;
.react-multiple-carousel__arrow--left {
    left: calc(0% + 0px);
  
}
.react-multiple-carousel__arrow
{

    background:white;

    opacity: 1;
 
    border: 1px solid #00000026;

&:before {
    font-size: 20px;
    color: #000;
    display: block;
    font-family: revicons;
    text-align: center;
    z-index: 2;
    position: relative;
}
    }
.react-multiple-carousel__arrow--right {
    right: calc(0% + 0px);
}
.mainclass{
    height: 23rem;
    width: 94%;
    display: flex;
    align-items: center;
     border: 0.5px solid #EBEBEB;
    justify-content: space-around;
    padding-left: 39px;
    .divlab{
        display: flex;
        margin-top: 1.5rem;
        justify-content: space-between;
        display: flex;
    justify-content: space-between;
    width: 296px;
        label{
            cursor: pointer;
             border: 1px solid #EBEBEB;
    width: 140px;
    height: 49px;
    
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    &:hover{
        transform: scale(1.03);
    }
        }
    }
    .subimagetag{
        height: 21rem;
    width: 50%;
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100% 100%;
    margin-top: 2rem;
    }
}
.custom{
    background-repeat: no-repeat;
    background-size: 100% 100%;

    
    width: 95%;
    height: 21rem;
    &:nth-child(1){
        border: 0.5px solid #8080804a;
    }
}

.cashbakcard{
    border-radius: 3px;
    border: solid 1px #d9e0e2;
    background-color: #ffffff;
    position: relative;
    width: 95%;
    &:hover{
        transform: scale(1.03);
    }
}



        .react-multi-carousel-track {
    .react-multi-carousel-item{
display: flex;
justify-content: space-evenly;
align-items: center;

    }

}
    }
}

//mobile
.roicoinmain-mobile{
    width: 100%;
display: none;
height: 105px;
align-items: center;
bottom: 0;
justify-content: center;
padding-left: 8%;
padding-right: 8%;
background-color: white;
z-index: 9;
position: fixed;
.img{
    width: 7rem;
}
.pricesection{
    display: flex;
    align-items: center;
}
.roicoin{
    
    border: 0.5px solid #EBEBEB;

height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
h4{
    font-family: Montserrat;
font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 24px;
display: flex;
align-items: center;
margin: 0;
color: #100F0D;

}
p{
    font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 24px;
display: flex;
align-items: center;
margin: 0;
padding-right: 9px;
padding-left: 9px;
color: #b3b9c7;
}
label{
    font-family: Montserrat;
height: 49px;
width:145px;
font-style: normal;
font-weight: bold;
font-size: 15px;
line-height: 18px;
border:0.5px solid #EBEBEB;
justify-content: center;
display: flex;
align-items: center;
text-align: center;
margin:0;
color: #100F0D;
&:hover{
    transform: scale(1.03);
}
}
}
}

//
.roicoinmain{
    width: 100%;
    display: flex;
    height: 100px;;
    align-items: center;
    margin-top: 3rem;
    justify-content: center;
    position: fixed;
    bottom: 0;
    background: white;
    z-index: 9;
    
    border: 0.5px solid #EBEBEB;
.pricesection{
    display: flex;
    align-items: center;
}
.roicoin{
 
    width: 100%;
height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
h4{
    font-family: Montserrat;
font-style: normal;
font-weight: bold;
font-size: 20px;
line-height: 24px;
display: flex;
align-items: center;
margin: 0;
color: #100F0D;

}
p{
    font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 20px;
line-height: 24px;
display: flex;
align-items: center;
margin: 0;
padding-right: 9px;
padding-left: 9px;
color: #b3b9c7;
}
label{
    font-family: Montserrat;
height: 49px;
width:145px;
font-style: normal;
font-weight: bold;
font-size: 15px;
line-height: 18px;
border:0.5px solid #EBEBEB;
justify-content: center;
display: flex;
align-items: center;
text-align: center;
margin:0;
color: #100F0D;
&:hover{
    transform: scale(1.03);
}
}
}
}
.seeall{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8.8rem;
    height: 3.2rem;

    margin: 3rem auto;
  &:hover{
    transform: scale(1.03);
  }
    
    h3{
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 18px;
        display: flex;
        align-items: center;
        text-align: center;
        margin: 0;
        cursor: pointer;
        margin-right: 6px;
        color: #100F0D;
    
    }
}
    .btnTalk{
        cursor: pointer;
        cursor: pointer;
        width: 8.8rem;
        height: 3.2rem;
        border: 0.5px solid #EBEBEB;
        display: flex;
        justify-content: center;
        align-items: center;
        
       background-color: white;
        overflow: hidden;
     
        transition: all 400ms ease;
        transition: all 400ms ease;
     
        * {
          transition: all 400ms ease;
        }

        img,
        svg {
          height: 40%;
          width: auto;
          margin-right: 6px;
        }
        svg {
          margin: 0 5px;
        }
        &::before {
          content: "";
          background-color: #100F0D;
          width: 100%;
          height: 60px;
          position: absolute;
          left: -100%;
          transform: rotateZ(45deg);
        }
        &:hover {
          &::before {
            transition: all 0.4s ease;
            left: 100%;
          }
        }
    
   
}
.outsidebrand{
    img{
        width: 2rem;
    }
}











//out
.outsidebrand
{
    height: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.5px solid #EBEBEB;
    border-top:none
}


.storiesclassbrand{
    display: flex;
    justify-content: space-between;
    overflow-x: auto;
    gap: 1.3%;
.plp{
    flex:0 0 24%;
    
    .substory{
   
    height: 410px;
    padding: 3%;
    border: 0.5px solid #EBEBEB;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

.animationdiv{
    
                
        animation: glow-dim 1s infinite;
        background-color: #d9d9d9;
    height: 3rem;
    width: 80%;
  
    
}
  
@keyframes glow-dim{
    0%{opacity: 1;}
    50%{opacity: 0.5;}
    100%{opacity: 1;}

}
    img{
        width: 16rem;
        height: 3.5rem;
        object-fit: contain;
    }

    label{
        font-family: Montserrat;
        width: 136px;
height: 54.75px;
left: 180px;

width: 12rem;
top: 565.37px;
justify-content: center;
align-items: center;
background: #FFCA05;
font-style: normal;
font-weight: bold;
font-size: 15px;
line-height: 13px;
display: flex;
align-items: center;
text-align: center;
margin: 0;
color: #FFFFFF;
cursor: pointer;
&:hover{
    transform: scale(1.03);
}
    }
  .btnTalk {
          cursor: pointer;
       
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
         
          overflow: hidden;
          border: 1px solid;
          transition: all 400ms ease;
          * {
            transition: all 400ms ease;
          }
          img,
          svg {
            height: 40%;
            width: auto;
            margin-right: 6px;
          }
          svg {
            margin: 0 5px;
          }
          &::before {
            content: "";
            background-color: white;
            width: 100%;
            height: 60px;
            position: absolute;
            left: -100%;
            transform: rotateZ(45deg);
          }
          &:hover {
            &::before {
              transition: all 0.4s ease;
              left: 100%;
            }
          }
        }

    p{
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 28px;
        height: 5rem;

        width: 16rem;

        display: flex;
        align-items: center;
        text-align: center;
        color: #100F0D ;
        margin: 0;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis
    }
}
}
}


//pl
.storiesclassplatorm{
    display: flex;
    justify-content: space-between;
   

.substory1{
cursor: pointer;
height: 400px;
    width: 19%;

    display: flex;
    img{
        width:100%;
    }

    &:hover{
        transform: scale(1.03);
    }
 

    label{
        font-family: Montserrat;
        width: 136px;
height: 36.75px;
left: 180px;
top: 565.37px;
justify-content: center;
align-items: center;
background: #FFCA05;
font-style: normal;
font-weight: bold;
font-size: 11px;
line-height: 13px;
display: flex;
align-items: center;
text-align: center;
margin: 0;
color: #FFFFFF; 
cursor: pointer;

    }
    .btnTalk {
        cursor: pointer;
     
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
       
        overflow: hidden;
        border: 1px solid;
        transition: all 400ms ease;
        * {
          transition: all 400ms ease;
        }
        img,
        svg {
          height: 40%;
          width: auto;
          margin-right: 6px;
        }
        svg {
          margin: 0 5px;
        }
        &::before {
          content: "";
          background-color: white;
          width: 100%;
          height: 60px;
          position: absolute;
          left: -100%;
          transform: rotateZ(45deg);
        }
        &:hover {
          &::before {
            transition: all 0.4s ease;
            left: 100%;
          }
        }
      }

    p{
        font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 10px;
line-height: 28px;
/* or 280% */

display: flex;
align-items: center;
text-align: center;

color: #100F0D ;
margin: 0;
    }
} 

}

//asda
.storiesclassprotocal{
    display: flex;
    justify-content: space-between;
    transition: all 600ms ease;
flex-wrap: wrap;
.substory2{
    border: 0.5px solid #EBEBEB;
    height: 160px;
    padding: 3%;
    width: 49%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
position: relative;
transition: all 600ms ease;
    &:hover{
        transform: scale(1.03);
    }
    img{
        height: 25% !important;
    }
    .btnTalk {
        cursor: pointer;
     
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
       
        overflow: hidden;
    
        transition: all 600ms ease;
        * {
          transition: all 600ms ease;
        }
        img,
        svg {
          height: 40%;
          width: auto;
          margin-right: 6px;
        }
        svg {
          margin: 0 5px;
        }
        &::before {
          content: "";
          background-color: white;
          width: 100%;
          height: 60px;
          position: absolute;
          left: -100%;
          transform: rotateZ(45deg);
        }
        &:hover {
          &::before {
            transition: all 0.6s ease;
            left: 100%;
          }
        }
      }
&:nth-child(3)
{
    margin-top: 2%;
}
&:nth-child(4)
{
    margin-top: 2%;
}
img{
    position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)
}
}


}
//@at-root
.storiesclass{
    display: flex;
    justify-content: space-between;
gap :1.2%;
overflow-x: auto;
&::-webkit-scrollbar{
    display: none;
}
.substory{
    border: 0.5px solid #EBEBEB;
    height: 410px;
    padding: 3%;
   flex:0 0 24%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .animationdiv{
    
                
        animation: glow-dim 1s infinite;
        background-color: #d9d9d9;
    height: 3rem;
    width: 80%;
  
    
}
  
@keyframes glow-dim{
    0%{opacity: 1;}
    50%{opacity: 0.5;}
    100%{opacity: 1;}

}
    img{
        width: 16rem;
        height: 3.5rem;
        object-fit: contain;
    }

    label{
        font-family: Montserrat;
       
        width: 12rem;
height: 54.75px;

justify-content: center;
align-items: center;
background: #FFCA05;
font-style: normal;
font-weight: bold;
font-size: 15px;
line-height: 13px;
display: flex;
align-items: center;
text-align: center;
margin: 0;
color: #FFFFFF;
cursor: pointer;
position: relative;
display: inline-block;
 box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
 7px 7px 20px 0px rgba(0,0,0,.1),
 4px 4px 5px 0px rgba(0,0,0,.1);
outline: none;
// &:hover{
//     transform: scale(1.03);
// }
    }



  
        .btnTalk {
          cursor: pointer;
       
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
         
          overflow: hidden;
          border: 1px solid;
          transition: all 400ms ease;
          * {
            transition: all 400ms ease;
          }
          img,
          svg {
            height: 40%;
            width: auto;
            margin-right: 6px;
          }
          svg {
            margin: 0 5px;
          }
          &::before {
            content: "";
            background-color: white;
            width: 100%;
            height: 60px;
            position: absolute;
            left: -100%;
            transform: rotateZ(45deg);
          }
          &:hover {
            &::before {
              transition: all 0.4s ease;
              left: 100%;
            }
          }
        }

    
    .custom-btn {
        width: 130px;
        height: 40px;
        color: #fff;
        border-radius: 5px;
        padding: 10px 25px;
        font-family: 'Lato', sans-serif;
        font-weight: 500;
        background: transparent;
        cursor: pointer;
        transition: all 0.3s ease;
        position: relative;
        display: inline-block;
         box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
         7px 7px 20px 0px rgba(0,0,0,.1),
         4px 4px 5px 0px rgba(0,0,0,.1);
        outline: none;
    }
      .btn-16 {
        border: none;
        color: #000;
      }
      .btn-16:after {
        position: absolute;
        content: "";
        width: 0;
        height: 100%;
        top: 0;
        left: 0;
        direction: rtl;
        z-index: -1;
        box-shadow:
         -7px -7px 20px 0px #fff,
         -4px -4px 5px 0px #fff,
         7px 7px 20px 0px #0002,
         4px 4px 5px 0px #0001;
        transition: all 0.3s ease;
      }
      .btn-16:hover {
        color: #000;
      }
      .btn-16:hover:after {
        left: auto;
        right: 0;
        width: 100%;
      }
      .btn-16:active {
        top: 2px;
      }
    
   
      
    p{
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 28px;
        height: 5rem;

        width: 16rem;

        display: flex;
        align-items: center;
        text-align: center;
        color: #100F0D ;
        margin: 0;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis
    }
}

}
//
.SotriesMain{
    width: 100%;
    padding-left: 6.2%;
    padding-right: 6.2%;
 .block-heading {
    margin-top: 5.5rem;
        font-size: 20px;
        font-weight: bold;
        color: #100F0D;
        display: flex;
        align-items: center;
        justify-content: center;
        &::after{
            background: #EBEBEB;
            height: 0.5px;
            flex: 1;
            content: '';
            margin-left: 24px;
        }
    }
.topStorysection
{
    justify-content: space-between;
width: 100%;

display: flex;
margin-top: 1.4rem;
.featureStore{
    width: 17rem;
    border: solid 1px #d9e0e2;
    height: 25.3rem;
    .fb-title{
        height: 81px;
        background-color: black;
        h1{
       
            width: 100%;
            text-align: center;
            font-size: 20px;
            font-weight: bold;
            font-stretch: normal;
            line-height: 81px;
            letter-spacing: 0.26px;
            text-align: center;
            color: #ffffff;
      
        }
    }
    .imgasection{
      
    
      display: flex;
        align-items: center;
        justify-content: center;
        height: 70px;
        border-radius: 3px;
        border: solid 1px #d9e0e2;
        background-color: #ffffff;
        width: 180px;
        cursor: pointer;
        /* margin: 25px auto; */
        display: flex;
        vertical-align: middle;
        margin-bottom: 18px;
        margin: auto;
        margin-top: 2rem;
    }
    h3{
        font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.55px;
    color: #4a4a4a;
    text-transform: uppercase;
    margin-bottom: 0;
    margin-bottom: 1rem;
    margin-top: 1rem;
    text-align: center;
    }
    label{
        width: 200px;
    height: 38px;
    border-radius: 3px;
    background-color: #cd3232;
    cursor: pointer;
    line-height: 38px;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.02px;
    text-align: center;
    color: #ffffff;
    text-transform: uppercase;
    margin: 60px 28px 0px 28px;

  
    }
    p{
        color: #2BA828 !important;
        font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.4px;
    text-align: center;

    }
}




.customCarosule
{
    background: #0000ff0a;
    display: flex;
    width: calc(100% - 19rem);
    flex-wrap: wrap;

    justify-content: space-between;
    height: 25.3rem;
    padding-left: 1%;
    flex-direction: column;
    position: relative;
    padding-top: 0.7%;
    padding-bottom: 0.6%;
    .prev{
        position: absolute;
        top: 50%;
        transform: translateX(-38%);
        background: white;
        height: 3rem;
        width: 3rem;
        border: 1px solid;
        cursor: pointer;
        /* top: 0; */
        left: 0;
        border-radius: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        font-weight: bold;
        color: #aaa0a0;
        background: white;
    }
    .next{
        position: absolute;
        top: 50%;
        transform: translateX(38%);
        background: white;
        height: 3rem;
        width: 3rem;
        cursor: pointer;
        border: 1px solid;
        /* top: 0; */
        right: 0;
        border-radius: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        font-weight: bold;
        color: #aaa0a0;
        background: white;
    }
    .cards{
        width: 32.33%;
        height: 121px;
        background: #fff;
        border: 0.5px solid #EBEBEB;

    display: flex;
    &:hover{
        transform: scale(1.03);
    }
    .cardfirst{
        width: 40%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        border-right: 0.5px solid #EBEBEB;
    }

    .cardsecond{
        height: 100%;
        padding-left: 8%;
        h3{
            font-size: 15px;
            font-weight: bold;
            line-height: 1.67;
            margin-top: 6px;
            color: #4a4a4a;
            margin-bottom: 0;
        }
        h5{
            font-size: 13px;
            line-height: 1.92;
            margin-top: 5px;
            color: #666666;
            margin-top: 17px;
            bottom: 9px;
            width: max-content;
        }
        p{
            color: #2BA828 !important;
            font-size: 14px;
            margin-bottom: 0;
            font-weight: bold;
        }
    }
    }
}
}
}


//bann
.bannersection
{
    width: 100%;
    padding-left: 6.2%;
    padding-right: 6.2%;
img{
    width:100%
}
}
.userdeleteheaderterimal{
    font-style: normal;
    font-weight: bold;
    font-size: 29px;
    line-height: 39px;
    display: flex;
  
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    height: 5rem;
    background: #100F0D;
    display: flex;
    align-items: center;
    justify-content: center;
}
.modal-dialog {
    max-width: 850px !important;
    margin: 1.75rem auto;
}
.coponmodel
{
    display: flex;
    justify-content: space-between;
    padding-left: 2rem;
    padding-right: 2rem;
    height: 20rem;
    align-items: center;
    .sub
    {
        cursor: pointer;
        &:nth-child(3){
            cursor: not-allowed;
            opacity: 0.5;
        }
        &:nth-child(4){
            cursor: not-allowed;
            opacity: 0.5;
        }
        &:hover{
            transform: scale(1.02);
        }
        .imgsection{
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
            height: 8rem;
            width: 8rem;
        border-radius: 510px;
        img{
            width: 4rem;
            height: 4rem;
        }
        }
        p{
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 16px;
            /* display: flex; */
            /* align-items: center; */
            text-align: center;
            color: #08152D;
            margin-top: 13px;
        }
    }
}
.modal-content {
    border:none !important;
    border-radius: 1.3rem;
    background-color: transparent !important;
}
.modal-body {
  
    background: white;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    z-index: 99999999999999999999999999999999999 !important;
}
.tersetting  {
   
  background-color: #08152D  !important;
}



.searchsection{
     /* height: 100%; */
    /* position: absolute; */
    background: white;
    // z-index: 99999999999999999999999999999999999999999999999999999999999999999999999;
    width: 100%;
    /* height: 100vh; */
    top: 112px;
    /* margin-top: 116px; */
    position: fixed;
    padding: 3%;
    height: calc(100vh - 113px);
    .maincontent{
       display: flex;
        height: 100%;
        justify-content: space-between;
    
    width: 100%;

        .first{
            width: 25%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .firstsub{
                background-size: 100% 100%;
                background-repeat: no-repeat;
                height: 48%;
                width: 100%;
            }
        }
    .searchlist{
        overflow: scroll;
        height: 100%;
        width: 73%;
        .inside{
            border: 0.5px solid #E6E8EB;
            height: 13rem;
   margin-bottom: 2rem;
            display: flex;
            width: 100%;
            &:hover{
                transform: scale(1.02);
            }
            .back{
                
    height: 100%;
    width: 14rem;
    background-size: 100% 100%;

            }
            .sub{
                display: flex;
                justify-content: space-between;
                height: 100%;
                padding-top: 2rem;
                padding-right: 4%;
                padding-left: 4%;
                width: 100%;
                .thridse{
                    h3{
                        font-style: normal;
font-weight: 500;
font-size: 32px;
line-height: 39px;
display: flex;
align-items: center;
text-align: right;

color: #100F0D;
margin-bottom: 0;
                    }

               h2{
                font-style: normal;
                font-weight: 600;
                font-size: 22px;
                line-height: 27px;
                display: flex;
                align-items: center;
                text-align: right;
                
                color: #100F0D;
                margin-bottom: 0;
               }     
                }
.secondsub{
    position: relative;
    .labelsection{
        display: flex;
        width: 11rem;
        justify-content: space-between;
        position: absolute;
        bottom: 27px;
        label{
            border: 0.5px solid #E6E8EB;
    margin-bottom: 0;
    width: 5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
cursor: pointer;
    &:hover{
        transform: scale(1.04);
    }
        }
    }
                .title{
display: flex;
align-items: center;


h5{
    font-style: normal;
font-weight: bold;
font-size: 18px;
line-height: 22px;
display: flex;
align-items: center;

color: #000000;
margin-bottom: 0;
margin-right: 0.3rem;
}
                }
      p{
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 39px;
        display: flex;
        align-items: center;
        margin-bottom: 0;
        color: #000000;
      }
    }
            }
        }
    }
}
}


.binner{
    position: fixed;
    background: white;
    width: 100%;
    border: 1px solid #EBEBEB;
    height: 80px;
    display: flex;
    justify-content: space-between;
    bottom: 0;
    .leftside{
        display: flex;
        height: 100%;
        div
        {
            border-right: 1px solid #EBEBEB;
            font-style: normal;
font-weight: bold;
font-size: 20px;
line-height: 40px;
/* or 200% */
justify-content: center;
display: flex;
align-items: center;
text-align: center;
width: 230px;
color: #100F0D;
height: 100%;
&:hover{
    transform: scale(1.03);
}
        }
    }

    .righside
{
    display: flex;
    height: 100%;
    div
    {
        border-left: 1px solid #EBEBEB;
        font-style: normal;
font-weight: bold;
font-size: 20px;
line-height: 40px;
/* or 200% */
justify-content: center;
display: flex;
align-items: center;
text-align: center;
width: 230px;
color: #100F0D;
height: 100%;
&:hover{
    transform: scale(1.03);
}
    } 
}
}

.loadinfullpage{
    position: absolute;
    top: 0%;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
}

.binnercutom{
    position: fixed;
    background: white;
    width: 100%;
    background-color: #100F0D;
    height: 80px;
    display: flex;
    justify-content: space-between;
    bottom: 0;
    padding-left: 2%;
    padding-right: 2%;
    align-items: center;
    z-index: 9;
    &:hover{
       img{
        transform: scale(1.03);
       }
    }
}

@media screen and (max-width: 9000px) and (min-width: 1600px)  {
    .carsoulesection {
        width: 100%;
 
    
    }


}


@media screen and (max-width: 700px) and (min-width: 0px)  {
    .roicoinmain {
        display: none;
     
    
    }
    .carsoulesection {
        width: 100%;
     
display: block;
    
    }
   .roicoinmain-mobile{
       display: block;
   }
    .storiesclass {
        .substory {
        
 
        padding: 9%;
        flex: 0 0 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
}
.storiesclassbrand {.plp {
    flex: 0 0 100%;
}}
.storiesclassplatorm {
    overflow:scroll;
    
    .substory1 {

    width: 100%;
    display: flex;
    flex: 0 0 100%;
}}


.storiesclassprotocal{
     .substory2 {


    padding: 3%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    position: relative;
    margin-bottom: 2rem;
    height: 108px;
img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 35%;
    }
     }
    }

    .footerend {
margin-top: 2rem;
    .subk {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 7rem;
        img{
width: 2rem;
        }
    }}
}