

//

.SotriesMain{
    width: 100%;
    padding-left: 6.2%;
    padding-right: 6.2%;
    .labelsection{
        display: flex;
        width: 100%;
        margin-top: 1.4rem;

        label{
            height: 52px;
            border-radius: 5px;
            border: solid 1px #e3e4e4;
            border-bottom: none;
            line-height: 52px;
            padding: 0px 15px;
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0.18px;
            color: #4a4a4a;
            text-transform: uppercase;
            cursor: pointer;
            border-bottom-right-radius: 0px;
            border-bottom-left-radius: 0px;
        margin-bottom: 0;
        &:hover{
            transform: scale(1.03);
        }
        }
    }
 .block-heading {
    margin-top: 5.5rem;
    margin-bottom: 5.5rem;
        font-size: 20px;
        font-weight: bold;
        color: #100F0D;
        display: flex;
        align-items: center;
        justify-content: center;
        &::after{
            background: #EBEBEB;
            height: 0.5px;
            flex: 1;
            content: '';
            margin-left: 24px;
        }
    }
    .ss{
        margin-top: 0;
position: relative;
    top: -15px;

}
.Poplularsection
{
  
width: 100%;



.featureStore{
    width: 17rem;
    border: solid 1px #d9e0e2;
    height: 25.3rem;
    .fb-title{
        height: 81px;
        background-color: black;
        h1{
       
            width: 100%;
            text-align: center;
            font-size: 20px;
            font-weight: bold;
            font-stretch: normal;
            line-height: 81px;
            letter-spacing: 0.26px;
            text-align: center;
            color: #ffffff;
      
        }
    }
    .imgasection{
      
    
      display: flex;
        align-items: center;
        justify-content: center;
        height: 70px;
        border-radius: 3px;
        border: solid 1px #d9e0e2;
        background-color: #ffffff;
        width: 180px;
        cursor: pointer;
        /* margin: 25px auto; */
        display: flex;
        vertical-align: middle;
        margin-bottom: 18px;
        margin: auto;
        margin-top: 2rem;
    }
    h3{
        font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.55px;
    color: #4a4a4a;
    text-transform: uppercase;
    margin-bottom: 0;
    margin-bottom: 1rem;
    margin-top: 1rem;
    text-align: center;
    }
    label{
        width: 200px;
    height: 38px;
    border-radius: 3px;
    background-color: #100F0D;
    cursor: pointer;
    line-height: 38px;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.02px;
    text-align: center;
    color: #ffffff;
    text-transform: uppercase;
    margin: 60px 28px 0px 28px;

  
    }
    p{
        color: #2BA828 !important;
        font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.4px;
    text-align: center;

    }
}




.customCarosule
{
    background: #0000ff0a;
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    justify-content: space-evenly;
    height: 44.3rem;
    /* padding-left: 1%; */
    position: relative;
    padding-top: 3%;
    .prev{
        position: absolute;
        top: 50%;
        transform: translateX(-38%);
        background: white;
        height: 3rem;
        width: 3rem;
        border: 1px solid;
        cursor: pointer;
        /* top: 0; */
        left: 0;
        border-radius: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        font-weight: bold;
        color: #aaa0a0;
        background: white;
    }
    .next{
        position: absolute;
        top: 50%;
        transform: translateX(38%);
        background: white;
        height: 3rem;
        width: 3rem;
        cursor: pointer;
        border: 1px solid;
        /* top: 0; */
        right: 0;
        border-radius: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        font-weight: bold;
        color: #aaa0a0;
        background: white;
    }
    .cards{
        width: 21.33%;
       position: relative;
        background: #fff;
        border: 0.5px solid #EBEBEB;

    height: 19rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    &:hover{
        transform: scale(1.03);
    }
    p{
        color: #2BA828 !important;
        font-size: 20px;
        margin-bottom: 0;
        font-weight: bold;
        margin-top: 1.3rem;
        margin-bottom: 1.3rem;
    }
    .cardfirst{
        width: 40%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        border-right: 0.5px solid #EBEBEB;
    }

    .cardsecond{
        height: 100%;
        padding-left: 8%;
        h3{
            font-size: 15px;
            font-weight: 500;
            line-height: 1.67;
        
    margin-top: 1rem;

            color: #4a4a4a;
            margin-bottom: 0;
        }

        .cpnbtn{
            right: 0px;
            left: 0;
            bottom: 26px;
            position: absolute;
            margin: auto;
            font-size: 12px;
            text-transform: uppercase;
            letter-spacing: 0.02px;
            font-weight: bold;
            line-height: 34px;
            width: 186px;
            height: 34px;
            padding: 0 10px 0 0;
            cursor: pointer;
            text-align: right;
            color: green;
            border: 1px dashed #100F0D;
            border-radius: 3px;
            background-color: #ffeeee;
            &::before{
                position: absolute;
    right: 15px;
    width: 30px;
    height: 2px;
    content: '';
    background-color: #100F0D;
    top: -1px;
    right: 30px;
    transition: transform .3s ease-out;
    backface-visibility: hidden;
}
&::after{
    position: absolute;
    right: 15px;
    width: 30px;
    height: 4px;
    content: '';
    bottom: -3px;
    background-color: #100F0D;
}
.p1{
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 152px;
    height: 36px;
    margin: -1px 0 0 -1px;
    padding-left: 10px;
    white-space: nowrap;
    color: white;
    border-radius: 3px 0 0 3px;
    background: #100F0D;

}
.p2 {
    position: absolute;
    top: 0;
    right: 15px;
    overflow: hidden;
    width: 45px;
    height: 100%;
}
 .t1 {
    position: absolute;
    top: 0;
    overflow: hidden;
    width: 63.64px;
    height: 63.64px;
    transform: translate(-17px, -2px) rotate(
-45deg
);
    background: #100F0D;
}
 .t1 {
    position: absolute;
    top: 0;
    overflow: hidden;
    width: 63.64px;
    height: 63.64px;
    transform: translate(-17px, -2px) rotate(-45deg);
    background: #100F0D;
}
 .t2 {
    position: absolute;
    top: 17.5px;
    right: -25px;
    width: 30px;
    height: 30px;
    transform: rotate(
45deg
);
    background: green;
}
span {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    display: block;
    width: 162px;
    height: 100%;
    color: #fff;
}

            }
        }
        h5{
            font-size: 13px;
            line-height: 1.92;
            margin-top: 5px;
            color: #666666;
            margin-top: 17px;
            bottom: 9px;
            width: max-content;
        }
     
    }
    }
}
}



//bann
.bannersection
{
    width: 100%;
    padding-left: 6.2%;
    padding-right: 6.2%;
img{
    width:100%
}
}


@media screen and (max-width: 700px) and (min-width: 0px)  {

    .ss{
        margin-top: 2.5rem !important;
        position: relative;
            top: 0px !important;
        
        }
    .block-heading {
        margin-top: 2.5rem !important;
        margin-bottom: 2.5rem !important;
            font-size: 20px;
            font-weight: bold;
            color: #100F0D;
            display: flex;
            align-items: center;
            justify-content: center;
            &::after{
                background: #EBEBEB;
                height: 0.5px;
                flex: 1;
                content: '';
                margin-left: 24px;
            }
        }


}