.nav-icon-box{
  display: block;
  cursor: pointer;
}

.nav-icon-box:hover{
    transform: scale(1.1);
    transition: all ease 1s;
}

.dropdownforcountry{
  position: fixed;
background: white;
top: 77px;
width: 341px;
height: 591px;
margin-left: 5%;
z-index: 9999;
border-radius: 15px;
border: 1px solid #EFEFEF;
background: #FFF;
overflow: hidden;
}
.seaarchmatch{
  border-radius: 35px;
background: rgba(245, 245, 245, 0.48);
width: 288px;
height: 51px;
width: 288px;
height: 51px;
color: #100F0D;
font-size: 13px;
font-family: Montserrat;
font-weight: 500;
line-height: 35px;
padding-left: 14px;
padding-right: 14px;
line-height: 35px;
padding-left: 14px;
padding-right: 14px;
margin: auto;
margin-top: 2rem;
margin-bottom: 1.3rem;
display: flex;
align-items: center;



input{
  color: #100F0D;
font-size: 13px;
font-family: Montserrat;
font-weight: 500;
line-height: 35px;
border: 0px;
background: rgba(245, 245, 245, 0.48);
margin-left: 10px;
&:focus{
  border:0px;
  outline-offset: 0pc;
  outline: 0px;
}
}
}
.dataCountry{
  height: 100%;
  overflow: scroll;
}
.dropdoqninside{
  border-radius: 15px;
border: 0.5px solid #EFEFEF;
background: #FFF;
width: 288px;
height: 62px;
align-items: center;
padding-left: 14px;
display: flex;
margin: auto;
margin-bottom: 1.2rem;
img{
  height: 15px
}
&:hover{
  transform: scale(1.1);
}
p{
  color: #100F0D;
font-size: 14px;
font-family: Montserrat;
font-weight: 500;
margin-bottom: 0;
margin-left: 10px;

}
}
.bordertable{
  display: flex;
  align-items: center;
  height: 37px;

  border: 1px solid #E5E5E5;
  /* width: 35px; */
  position: absolute;
  left: 0%;
  transition: ease .3s;
  border-radius: 35px;
  cursor: pointer;  
  img{
    transition: ease .3s;
    // transform: rotate(360);
  }
  .imgs{
    transition: ease .3s;
    height: 13px;
    border-radius: 100px;
  }
  p{
    color: #100F0D;
font-size: 13px;
font-family: Montserrat;
font-weight: 500;
margin-bottom: 0;
margin-left: 5px;
margin-right: 20px;
  }
}

.master-nav{
    top:0;
    position: fixed;
    z-index: 5;
    height: 76px;
    width: 100vw;
    padding: 0px 10vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FFFFFF;
    border-bottom:  0.5px solid #E5E5E5;
}

.nav-menu-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-each-menu{
    cursor: pointer;
    font-family: 'Montserrat';
    font-weight: 300;
    font-size: 15px;
    line-height: 18px;
    color: #100F0D;
    // width: 150px;
    margin-right: 4vw;
    text-align: center;
    &:hover{
       transform: scale(1.2);
       transition: all ease .5s;
       font-weight: 500;
    }
}

.nav-button{
    padding: 10px 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    background: #100F0D;
    border-radius: 10px;
    color: #FFFFFF;
    cursor: pointer;
    overflow: hidden;
    position: relative;
  }

  .nav-button::after {
    background: #FFFFFF;
    content: "";
    height: 155px;
    left: -75px;
    opacity: 1;
    position: absolute;
    top: -50px;
    -webkit-transform: rotate(35deg);
    transform: rotate(35deg);
    transition: all .55s cubic-bezier(.19,1,.22,1);
    width: 50px;
    z-index: -10;
  }

  .nav-button:hover::after{
    left: 120%;
    transition: all .55s cubic-bezier(.19,1,.22,1);
    z-index: 50;
  }

  .mob-nav1{
    display: none;
  }

  
  ////
  
.ham {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hamRotate.active {
  transform: rotate(45deg);
}
.hamRotate180.active {
  transform: rotate(180deg);
}
.line {
  fill:none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke:#000;
  stroke-width:5.5;
  stroke-linecap:round;
}

.ham4 .top {
  stroke-dasharray: 40 121;
}
.ham4 .bottom {
  stroke-dasharray: 40 121;
}
.ham4.active .top {
  stroke-dashoffset: -68px;
}
.ham4.active .bottom {
  stroke-dashoffset: -68px;
}

.mob-nav-menu-box{
  display: none;
}

.side-all-menu{
  display: none;
}

.full-mob-nav{
  display: none;
}

@keyframes slide-in {
  100% { transform: translateY(0%); }
}

@keyframes slide-out {
  100% { transform: translateY(-50%); }
}

  /// 

  @media screen and (min-width: 300px) and (max-width: 900px) {
    .nav-icon-box{
      display: none;
    }
    .bordertable{
      display: none;
    }

    .nav-menu-box{
      display: none;
    }

    .mob-nav-menu-box{
      width: 100vw;
      display: flex;
      // flex-direction: column;
      height: auto;
      margin-top: 75px;
      border-bottom:  0.5px solid #E5E5E5;
      padding: 15px 5vw;
      background: white;
      justify-content: space-around;
      // justify-content: space-evenly;
      // align-items: center;
    }

    .mob-nav1{
      width: 90vw;
      margin: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .full-mob-nav{
      display: block;
      animation: slide-in .4s forwards;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
    }

    .close-mob-nav{
      display: block;
      animation: slide-out .4s forwards;
      transform: translateY(0%);
      -webkit-transform: translateY(0%);
    }

    .nav-each-menu{
      width: fit-content;
      // margin-left: 40px;
    }

    .mob-each-side-menu{
      margin-Left: 10vw; 
      margin-top: 3vh;
      cursor: pointer;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      color: #FFFFFF;
      font-family: 'Montserrat';
      &:hover{
          margin-right: 10px;
          transform: scale(1.05);
          -webkit-transform: scale(1.05);
          transition: all .1s ease-in;
      }
    }
  
    .mob-menu-selected{
      margin-Left: 10vw; 
      margin-top: 3vh;
      cursor: pointer;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #FFFFFF;
      font-family: 'Montserrat';
      &:hover{
          margin-right: 10px;
          transform: scale(1.05);
          -webkit-transform: scale(1.05);
          transition: all .1s ease-in;
      }
    }

    .side-all-menu{
      display: block;
      padding: 3vh 0px;
      background-color: #100F0D;
    }

  }