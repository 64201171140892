.conbinsection{
    background: #0000ff0a;
    margin-left: 6.2%;
    margin-right: 6.2%;
.carsoulesectioncashback
{
    width: 100%;
    height: 23rem;
    padding-left:1.2%;
    padding-right: 1.2%;

    .block-heading {
        margin-top: 3.5rem;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.18px;
        color: #4a4a4a;
        display: flex;
        align-items: center;
        justify-content: center;
        &::after{
            background: #EBEBEB;
            height: 0.5px;
            flex: 1;
            content: '';
            margin-left: 24px;
        }
    } 
    .react-multi-carousel-list{
height: 100%;
width: 100%;
background: transparent;
margin-top: 0rem;
.react-multiple-carousel__arrow--left {
    left: calc(0% + 0px);
  
}
.react-multiple-carousel__arrow
{

    background:white;

    opacity: 1;
 
    border: 1px solid #00000026;
&:before {
    font-size: 20px;
    color: #000;
    display: block;
    font-family: revicons;
    text-align: center;
    z-index: 2;
    position: relative;
}
    }
.react-multiple-carousel__arrow--right {
    right: calc(0% + 0px);
}


.cashbakcard{
    border-radius: 3px;
    border: solid 1px #d9e0e2;
    background-color: #ffffff;
    position: relative;
    width: 95%;
 
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &:hover{
        transform: scale(1.03);
    }
    img{
        margin-top: 2rem;
        margin-bottom: 1.5rem;
    }
    h5{
        font-size: 13px;
    font-weight: 500;
    letter-spacing: normal;
    color: #6e6e6e;
    margin-top: 14px;
    text-transform: none;
    margin-bottom: 2.6rem;
    }
    h2{
        font-weight: 500;
    font-stretch: normal;
    font-size: 13px;
    color: #2BA828 !important;
    }
    label
    {
        height: 40px;
        border-radius: 3px;
        background-color: #100F0D;
        margin: auto;
        line-height: 34px;
        font-size: 12px;
        font-weight: bold;
        right: 0px;
        left: 0;
        bottom: 20px;
        /* position: absolute; */
        letter-spacing: 0.02px;
        text-align: center;
        color: #ffffff;
        cursor: pointer;
        text-transform: uppercase;
        border: 2px solid #100F0D;
        width: 226px;
        margin-bottom: 1rem;
        margin-top: 1.5rem;
    
    }
}



        .react-multi-carousel-track {
    .react-multi-carousel-item{
display: flex;
justify-content: space-evenly;
align-items: center;
padding-left: 13px;
padding-right: 13px;
    }

}
    }
}
}
.selector{
    display: flex;
    padding-left: 6.2%;
    padding-right: 6.2%;
    align-items: center;
    .card{
        height: 99px;
    border-radius: 5px;
    border: solid 1px #e3e4e4;
    border-bottom: none;
    padding: 0px 26px;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.18px;
    color: #4a4a4a;
    text-transform: uppercase;
    cursor: pointer;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    align-items: center;
    justify-content: center;
    &:hover{
        transform: scale(1.03);
    }
    p{
        font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.18px;
    color: #4a4a4a;
    text-transform: uppercase;
    cursor: pointer;
    
    margin-top: 17px;
    margin-bottom: 0;

    }
    }
}

.block-heading-cutom

{
    font-family: HelveticaNeue;
    font-size: 20px;
    font-weight: bold;
    color: #100F0D;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
    padding-left: 6.2%;
    padding-right: 6.2%;
    margin-bottom: 3rem;
    &::after{
        background: #d8d8d8;
        height: 2px;
        flex: 1;
        content: '';
        margin-left: 24px;
    }
}
