
.skeltonmain
{
    display: flex;
    justify-content: space-between;
    grid-gap: 1.2%;
    gap: 1.2%;
    overflow-x: auto;
    

    .sub{

        border: 0.5px solid #EBEBEB;
          height: 410px;
            padding: 3%;
            flex: 0 0 24%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
        

            .div1{
                
                    animation: glow-dim 1s infinite;
                    background-color: #d9d9d9;
                height: 3rem;
                width: 80%;
              
                
            }
              
            @keyframes glow-dim{
                0%{opacity: 1;}
                50%{opacity: 0.5;}
                100%{opacity: 1;}
            }

            .div2{
                
                animation: glow-dim 1s infinite;
                background-color: #d9d9d9;
            height: 8rem;
            width:80%;
          
            
        }
          
        @keyframes glow-dim{
            0%{opacity: 1;}
            50%{opacity: 0.5;}
            100%{opacity: 1;}
        }

        .div3{
                
            animation: glow-dim 1s infinite;
            background-color: #d9d9d9;
        height: 3rem;
        width: 50%;
      
        
    }
      
    @keyframes glow-dim{
        0%{opacity: 1;}
        50%{opacity: 0.5;}
        100%{opacity: 1;}
    }

        }
}
.topSection{
    margin-top: 7rem;
    display: flex;
    width: 100%;
    padding-right: 6.2%;
    padding-left: 6.2%;
    height: calc(100vh - 11rem);
    .girlsection{
        width: 35%;
 
        position: relative;
        img{
            width: 93%;
            height: 86%;
            object-fit: contain;
            position: absolute;
            left: 0;
            
            bottom: 0;
        }
    }
    .sub{
        width: 65%;
        display: flex;
    flex-direction: column;
    justify-content: center;
   
       h2{
           font-size: 50px;
           font-weight: bold;
           color: #100F0D;
           margin: 0;
       } 
     p{
        font-size: 25px;
        font-weight: 2000;
        color: #100F0D;
    } 

    .label{
        margin-top: 2rem;
        width: 36rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
   
label{

    border: 0.5px solid #EBEBEB !important;
  display: flex;
        align-items: center;
        justify-content: center;
   
    &:hover{
     
            transform: scale(1.03);
       
    }
}
.btnTalk1 {
    cursor: pointer;
    width: 17rem;;
    height: 4.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
   background-color: #100F0D;
    overflow: hidden;
    border: 1px solid;
    transition: all 400ms ease;
    * {
      transition: all 400ms ease;
    }
    img,
    svg {
      height: 40%;
      width: 10rem;
      margin-right: 6px;
    }
    svg {
      margin: 0 5px;
    }
    &::before {
      content: "";
      background-color: white;
      width: 100%;
      height: 60px;
      position: absolute;
      left: -100%;
      transform: rotateZ(45deg);
    }
    &:hover {
      &::before {
        transition: all 0.4s ease;
        left: 100%;
      }
    }
  }


        .btnTalk {
            cursor: pointer;
            width: 17rem;;
            height: 4.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
           
            overflow: hidden;
            border: 1px solid;
            transition: all 400ms ease;
            * {
              transition: all 400ms ease;
            }
            img,
            svg {
              height: 40%;
              width: auto;
              margin-right: 6px;
            }
            svg {
              margin: 0 5px;
            }
            &::before {
              content: "";
              background-color: #100F0D;
              width: 100%;
              height: 60px;
              position: absolute;
              left: -100%;
              transform: rotateZ(45deg);
            }
            &:hover {
              &::before {
                transition: all 0.4s ease;
                left: 100%;
              }
            }
          }
    }
    }

}




//@at-root

.carsoulesection{
    margin-top: 3.7rem;
    display: none;
    width: 100%;
    padding-right: 6.2%;
    padding-left: 6.2%;

    .girlsection{
        width: 35%;
 
        position: relative;
        img{
            width: 100%;
            height:100%;
            object-fit: contain;
            position: absolute;
            left: 0;
            
            bottom: 0;
        }
    }
    .sub{
        width: 100%;
        display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
       h2{
           font-size: 25PX;
           font-weight: bold;
           color: #100F0D;
           margin: 0;
       } 
     p{
        font-size: 18px;
        font-weight: 2000;
        color: #100F0D;
        margin-top: 2rem;
    } 

    .label{
        margin-top: 2rem;
        width: auto;
        
            align-items: center;
            justify-content: space-between;
   
label{

    border: 0.5px solid #EBEBEB !important;

        align-items: center;
        justify-content: center;
   
    &:hover{
     
            transform: scale(1.03);
       
    }
}
.btnTalk1 {
    cursor: pointer;
    width: 14rem;;
    height: 3.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
   background-color: #100F0D;
    overflow: hidden;
    border: 1px solid;
    transition: all 400ms ease;
    margin-top: 1.5rem;
    * {
      transition: all 400ms ease;
    }
    img,
    svg {
      height: 35%;
      width: auto;
      margin-right: 6px;
    }
    svg {
      margin: 0 5px;
    }
    &::before {
      content: "";
      background-color: white;
      width: 100%;
      height: 60px;
      position: absolute;
      left: -100%;
      transform: rotateZ(45deg);
    }
    &:hover {
      &::before {
        transition: all 0.4s ease;
        left: 100%;
      }
    }
  }


        .btnTalk {
            cursor: pointer;
            width: 14rem;;
            height: 3.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
           
            overflow: hidden;
            border: 1px solid;
            transition: all 400ms ease;
            * {
              transition: all 400ms ease;
            }
            img,
            svg {
              height: 40%;
              width: auto;
              margin-right: 6px;
            }
            svg {
              margin: 0 5px;
            }
            &::before {
              content: "";
              background-color: #100F0D;
              width: 100%;
              height: 60px;
              position: absolute;
              left: -100%;
              transform: rotateZ(45deg);
            }
            &:hover {
              &::before {
                transition: all 0.4s ease;
                left: 100%;
              }
            }
          }
    }
    }


}
///
.m-navbar{
    display: none;
    .inputdiv{
        position: relative;
        height: 3rem;
        border-bottom: 0.5px solid #EBEBEB;
        input{
            color: #002A51;
            border: none;
            height: 3rem;
            font-size: 12px;
            border-bottom: 0.5px solid #EBEBEB;
            padding-left: 6.2%;
            padding-left: 6.2%;
        }
        img{
            position: absolute;
            top: 13px;
            right: 6.2%;
            width: 20px;
        }
    }
.sub{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 85px;
    position: relative;
    .img{
        position: absolute;
        top:29.5px;
        left: 5%;
    }
}
    .m-top
{
    display: flex;
  background: #100F0D ;
  align-items: center;
  height: 2rem;
  overflow: scroll;
    a{
        margin-right: 20px; 
        font-style: 12px !important;
        font-style: normal;
        font-weight: 600;
        color: white;
        margin-left: 5%;
        text-decoration: none;

    }
    p{
 font-style: 12px !important;
 margin: 0;
 white-space: nowrap;

    }
}
}
.modal-backdrop.show {
    opacity: 0.5 !important;
}

.tersetting  {
   
    background-color: #08152D  !important;
  }
  
.homenavbar
{
    .Topmain{
        background-color: #100F0D;
        border-bottom: 0;
        min-height: 60px;
        height: 60px;
    
    .topfirst{
        display: flex;
        background: #100F0D;
        position: relative;
.iconav
        {
            position: relative;
            float: left;
            margin-top: -10px;
            z-index: 1;
            background-color: #100F0D;
            border-radius: 0 0 4px 4px;
            width: 208px;
            height: 122px;
            background-position: center;
            transition: all .3s;
            transition: all .3s;
        }
        p{
            font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 17px;
display: flex;
align-items: center;
text-align: center;

color: #FFFFFF;
margin-bottom: 0;
        }
    }
    .secondtop{
        height: 3rem;

    }
}
}


.header-wrapper{
 
    position: fixed;
    right: 0;
    top: 0;
    left: 0;
    z-index: 9;
    transition: all .3s;
    transform: translateZ(0);
    height: 0;
    .sub-header
    {
        height: 4.5rem;
        line-height: 39px;
        top: 60px;
     background-color: white;
        letter-spacing: 0.2px;
        transition: top 0.3s;
        border-bottom: 0.5px solid #EBEBEB;
        .inputsection{
            width: 100%;
            position: relative;
            img{
                position:absolute;
                top: 25px;
                right: 30px;
                        }
            input{
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 22px;
                display: flex;
                align-items: center;
                color: #002A51;
                padding-left: 8%;
                width: 100%;
                height: 100%;
                border: none;
                &:focus::placeholder {
                    color: transparent;
                  }
            }
        }
        .logosection
        {
            border-left: 0.5px solid #EBEBEB;
            height: 100%;
            width: 17rem;
            display: flex;
            justify-content: center;
            align-items: center;
            &:hover{
                transform: scale(1.02);
            }
            img{
                width: 11rem;
                object-fit: contain;
            }
            p{
                margin-bottom: 0;
                margin-left: 8px;
                font-weight: 500;
                font-size: 17px;
            }
        }
    }
    .super-header {
        background-color: #100F0D;
    border-bottom: 0;
    height: 40px;
        .content-wrapper {
            padding-right: 6.2%;
            padding-left: 6.2%;
            margin-right: auto;
            margin-left: auto;
            width: 100%;
            height: 100px;
            &::after{
                clear: both;
    display: table;
    content: " ";
            }
.navbar-header 
            {
              
                position: relative;
                margin: 0 auto;
                width: 100%;

                .brand{
                    position: relative;
    float: left;
    margin-top: -10px;
    z-index: 1;
    border: 0.5px solid #EBEBEB;
    background-color: white;
    border-radius: 0 0 4px 4px;

    transition: all .3s;
    .sub{
        width: 228px;
        height: 145px;
        background-position: center;
        transition: all .3s;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover{
            img{
                transform: scale(1.03);
            }
       
        }
    }

    .scrollsub{
        width: 171px;
        height: 121px;
        background-position: center;
        transition: all .3s;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover{
            transform: scale(1.3);
        }
    }
                }
.top
                {
                    width: auto;
    position: relative;
    display: table;
    border-collapse: separate;
    padding-left: 6%;
    border: none;
    align-items:center;
    transition: all .3s;
    display: flex;
    height: 40px;
    justify-content: space-between;
    a{
        text-decoration: none !important;
    }
    p{
        font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 17px;
display: flex;
align-items: center;
text-align: center;
cursor: pointer;
color: #FFFFFF;
margin-bottom: 0;
&:hover{
    transform: scale(1.03);
}
    }
                }
            }
        }
    }
}


@media screen and (max-width: 700px) and (min-width: 0px)  {
    .carsoulesection{
        margin-top: 3.7rem;
        display: flex;
    }
    .m-navbar{
        display: block;
    }
.topSection{
    display: none;
}
    .header-wrapper{
        display: none;
    }
}