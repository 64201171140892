.footerpage {
    // height: 100vh;
    background: linear-gradient(341.13deg, #5E3199 261.43%, #5E3199 342.4%);
    display: flex;
    justify-content: space-between;
    align-items: start;
    position: relative;
    padding-top: 6rem;
    padding-bottom: 4rem;


    margin-top: 2.5rem;


    .footerinner {
        display: flex;
        align-items: self-start;
        justify-content: space-between;
        width: 100%;
        max-width: 1300px;
        margin: auto;
    }

    p {
        position: absolute;
        right: 7%;
        bottom: 5.6rem;
        color: #FFF;
        text-align: right;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 0;

    }

    .ImageSec {
        width: 246px;

        img {

            cursor: pointer;

            &:hover {
                transform: scale(1.03);
            }
        }

        .innerclass {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 2rem;
            flex-wrap: wrap;
            gap: 3rem;

            img {
                margin-bottom: 1rem;
                cursor: pointer;

                &:hover {
                    transform: scale(1.03);
                }
            }
        }
    }

    h1 {
        display: flex;
        width: 80px;

        flex-direction: column;
        justify-content: center;
        flex-shrink: 0;
        color: #FFF;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 1.4rem;
        white-space: pre;
        cursor: pointer;

        &:hover {
            transform: scale(1.02);
        }
    }

}


@media screen and (min-width: 300px) and (max-width: 900px) {


    .footerpage {

        .footerinner {
            display: flex;
            align-items: self-start;
            justify-content: space-between;
            width: 100%;
            flex-wrap: wrap;
        }

        .insidefooter {
            width: 50%;
        }

        .ImageSec {
            width: 246px;
            margin: auto;
            margin-top: 2rem;
        }

        p {
            display: none;
        }
    }


}