.container{
    height: 100vh;
    // background-color: red;
    color: black;
    padding: 10%;
    padding-top: 100px;
    padding-bottom: 100px;
}

h1{
    padding-bottom: 10px;
}
h2{
    padding-bottom: 10px;
}