.GoogleMap{
    height:100%;
    overflow: hidden;
    max-width: 80%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    // padding-top: 7rem;
}

svg {
    display: inline-block;
    vertical-align: middle;
    &:focus {
        outline: -webkit-focus-ring-color auto 0px;
    }
  }
  