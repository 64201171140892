@import "../../Static/scss/colours";
@import "../../Static/scss/mixin";

.landingPage {
  height: 0;
  // flex: 1;
  // display: flex;
  // flex-direction: column;

}

.pageWrap {
  height: 0;
  // flex: 1;
  // display: flex;
  // flex-direction: column;
  // overflow: auto;

}

.contactMaster {
  height: 100%;
  width: 100%;
  // color: #100F0D;
  color: #100F0D;
  overflow: hidden;

}

.titlesection {
  padding-top: 5%;
  width: 90%;
  margin: 0px 6%;
  margin-bottom: 4%;
  // margin: 1% 30% 0 30%;
  display: flex;
  flex-direction: column;

  // justify-content: center;
  // align-items: center;
  // text-align: center;
  .mainTitle {
    font-weight: 500;
    font-size: 35px;
    background: linear-gradient(341.13deg, #5E3199 261.43%, #5E3199 342.4%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-align: center;
    line-height: 40px;
  }

  .mainDesc {
    font-weight: 300;
    font-size: 20px;
    line-height: 35px;
    text-align: center;
  }
}

.querysection {
  max-width: 1300px;
  margin: auto;

  display: flex;
  flex-wrap: wrap;
}

.eachcard {
  cursor: pointer;
  height: 170px;
  margin: 30px 1.5%;
  width: 30.33%;
  padding: 30px;
  border: double 9px transparent;
  border-width: 1px 1px 1px 9px;
  border-radius: 15px;
  background-image: linear-gradient(white, white),
    // radial-gradient(circle at center, #5E3199 , #B580F2);
    linear-gradient(to bottom, #5E3199, #B580F2);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  border-top: 1px solid #e7e7e7;
  border-right: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;

  .queryTitle {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
  }

  .queryDesc {
    font-weight: 300;
    font-size: 14px;
    line-height: 27px;
  }
}

.eachcard:hover {
  background-color: #f5f5f5;
  transform: scale(1.1);
  transition: .6s ease;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}


@media only screen and (max-width: 600px) {
  .querysection {
    width: 90%;
    margin: 10px 4%;
    // display: inline-flex;
    // flex-wrap: wrap; 
    height: 90vh;
    overflow: auto;
  }

  .titlesection {
    padding-top: 8%;
    width: 90%;
    margin: 0px 6%;
    margin-bottom: 4%;
    // margin: 1% 30% 0 30%;
    display: flex;
    flex-direction: column;

    // justify-content: center;
    // align-items: center;
    // text-align: center;
    .mainTitle {
      font-weight: 500;
      font-size: 32px;
      line-height: 40px;
    }

    .mainDesc {
      font-weight: 300;
      font-size: 17px;
      line-height: 30px;
    }
  }

  .eachcard {
    cursor: pointer;
    height: 170px;
    margin: 20px 1.5%;
    width: 100%;
    padding: 30px;
    border: double 9px transparent;
    border-width: 1px 1px 1px 9px;
    border-radius: 15px;
    background-image: linear-gradient(white, white),
      // radial-gradient(circle at center, #5E3199 , #B580F2);
      linear-gradient(to bottom, #5E3199, #B580F2);
    background-origin: border-box;
    background-clip: padding-box, border-box;
    border-top: 1px solid #e7e7e7;
    border-right: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;

    .queryTitle {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      padding-bottom: 10px;
    }

    .queryDesc {
      font-weight: 300;
      font-size: 14px;
      line-height: 27px;
    }
  }

  .eachcard:hover {
    background-color: #f5f5f5;
    transform: scale(1.1);
    transition: .6s ease;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }

}