.pagemask {
  background-color: rgba(0, 0, 0, .75);
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: hidden !important;
  position: fixed !important;
  right: 0;
  top: 0;
  z-index: 1;
}

.playerData {
  display: flex;
  padding-right: 8%;
  /* max-width: calc(1300px - 500px); */

  height: 100vh;

  padding-left: 9%;
  display: flex;
  align-items: center;


  .textdata {
    width:
      467px;
    margin-top: 2rem;
    color: #100F0D;

  }
}

.thridvidePlay {
  height: 100vh;

  display: flex;
  margin: auto;

  .rightside {}

  .leftsie {
    height: 100%;
    max-width: 50%;
    overflow: hidden;
    object-fit: cover;
  }

  video {
    height: 100%;

  }
}

.modal-content {
  align-items: center;
  justify-content: center;
}

.founderCardSection {
  display: flex;
  flex-wrap: wrap;

  .cardsinnerFounder {
    cursor: pointer;
    width: 33.33%;
    height: 30.5vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 0.5px solid #EDEDED;
    background: rgba(255, 255, 255, 0.57);
    padding-left: 20px;
    transition: all .2s linear;
    overflow: hidden;

    .imgcirle {
      display: none;
    }

    h5 {
      display: none;
    }

    &:hover {
      div {
        transform: scale(1.03);
        transition: all .2s linear;
      }

      img {
        transform: scale(1.01);
        transition: all .2s linear;

      }
    }
  }

  div {
    transition: all .2s linear;
  }

  img {
    width: 7.5vw;
    // height: 28.5vh;   
    position: absolute;
    right: 0;
    transition: all .2s linear;
    bottom: 0;
  }

  h1 {
    color: #100F0D;
    font-size: 23px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 4px;
    padding-bottom: 0;
  }

  p {
    color: #100F0D;
    font-size: 14px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
}

.home-sec1 {
  // height: calc(100vh - 77px);
  // width: 100%;

  // font-family: "Montserrat";
  // overflow-x: hidden;

  width: 100%;
  font-family: "Montserrat";
  height: 100vh;
  padding-top: 70px;
  // margin-top: 35px;

}

.home-sec2 {
  margin-top: 0.5px;
  display: flex;
  height: 100vh;
  width: 100%;
  font-family: 'Montserrat';
  overflow: hidden;
  padding-top: 88px;

}

.home-sec1-part1 {
  height: calc(100vh - 320px);
  width: 100%;
  max-width: 1300px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.home-sec1-part1-left {
  height: 100%;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home-sec1-part1-right {
  height: 100%;
  width: 42vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.secondpartMian {
  width: 100%;
  background: linear-gradient(341.13deg, #5E3199 261.43%, #5E3199 342.4%);
}

.home-sec1-part2 {


  height: 250px;
  max-width: 1300px;
  margin: auto;
  display: flex;
  padding: 2rem 0%;
  justify-content: space-between;
  background: linear-gradient(341.13deg, #5E3199 261.43%, #5E3199 342.4%);
}

.home-sec1-part2-each {
  cursor: pointer;
  width: 25vw;
  // border: 1px solid #F3F4F5;
  border-radius: 10px;
  height: 90%;
  display: flex;
  flex-direction: column;

  position: relative;

  // padding-left: 8%;
  // padding-right: 8%;
  .innerzoomtext {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .textyearly {
    color: #FFF;
    margin-top: 20px;
    text-align: right;
    font-size: 18px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;


  }

  &:hover {
    .check1-text {
      color: #100F0D;
      display: inline-flex;
      margin-left: 9px;
      // margin-top: 5px;
      font-size: 15px;
      font-weight: 500;
    }

    .clickarrow-img {
      margin-left: 0.2rem;
      margin-top: 0.3rem;
      width: 20px;
    }

    .zoom1 {
      background: #fffbf0;
      height: 40px;
      text-align: center;
      width: 120px;
    }
  }
}

.inputboxsection {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 72px;

  img {
    margin-bottom: 2rem;
  }

  input {
    border-radius: 5px;
    border: 1px solid rgba(88, 87, 87, 0.25);
    background: #FFF;
    width: 75px !important;
    height: 75px;

    &:focus-visible {
      outline: -webkit-focus-ring-color auto 0px !important;
    }
  }

  label {
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 0px 0px 35px 35px;
    background: linear-gradient(341.13deg, #5E3199 261.43%, #5E3199 342.4%);
    width: 100%;
    height: 70px;
    flex-shrink: 0;
    position: absolute;
    left: 0;
    bottom: 0;
    cursor: pointer;

    &:hover {
      cursor: pointer;
      font-size: 23px;
    }
  }

}

.modal-backdrop {
  background-color: black;
  opacity: 0.6 !important;
}

.modal {
  // background-color: black;
  // opacity: 0.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
  border-radius: 35px;
  border: 1px solid rgba(88, 87, 87, 0.50);
  background: #FFF;
  width: 409px;
  height: 507px;
  flex-shrink: 0;
  max-width: 409px;
}

.zoom1 {
  margin-top: 20px;
  background: #f3f4f5;
  border: 0;
  border-radius: 30px;
  box-shadow: 0 10px 10px rgb(0 0 0 / 10%);
  cursor: pointer;
  display: inline-block;
  height: 40px;
  overflow: hidden;
  transition: all .3s ease-out;
  width: 40px;
}

.check1-text {
  display: none;


}

.clickarrow-img {
  height: 15px;
  width: 20px;
}

.home-sec1-part2-title {
  font-weight: 500;
  font-size: 30px;
  line-height: 30px;
  color: #fff;
}

.home-sec1-part2-desc {
  margin-top: 20px;
  font-weight: 300;
  font-size: 15px;
  line-height: 25px;
  color: #fff;
  height: 4.3rem;
}

.imagesBox {
  height: auto;
  width: 42vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: scaleX(1);
}

.rowbox {
  display: flex;
  height: 100px;
  width: 42vw;
  justify-content: end;
}

.linebox {
  height: 15px;
  width: 42vw;
  justify-content: end;
  display: flex;
  overflow: hidden;
}

.eachimgcircle {
  height: 50px;
  width: 50px;
  cursor: pointer;
}

.eachimgcircle1 {
  height: 100px;
  width: 100px;
  cursor: pointer;
}

.eachcirclebox {
  height: 100px;
  width: 100px;
  border: 2px solid #EFEFEF;
  border-radius: 1500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background: #FFFFFF;

  &:hover {
    .eachimgcircle {
      transform: scale(1.15);
      transition: ease .3s;
    }
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  25% {
    opacity: .25;
  }

  50% {
    opacity: .50;
  }

  75% {
    opacity: .75;
  }

  100% {
    opacity: 1;
  }
}

.eachlineh {
  height: 30px;
  width: 90%;
  animation: blink 2s linear infinite;
}

.eachlinev {
  height: 30px;
  width: 35px;
  animation: blink 2s linear infinite;
}

.eachhorzbox {
  height: 90%;
  width: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.eachvertbox {
  height: 15px;
  width: 100px;
  margin-left: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.linesgap {
  display: none;
}

.home-sec1-part1-title {
  font-weight: 400;
  font-size: 42px;
  line-height: 65px;
  color: #100F0D;
  // margin-top: 10px;
}

.home-sec1-part1-subtitle {
  font-weight: 400;
  font-size: 39px;
  line-height: 52px;
  color: #100F0D;
  margin-top: 10px;
}

.home-sec1-part1-desc {
  display: block;
  font-weight: 300;
  font-size: 22px;
  line-height: 40px;
  color: #100F0D;
  margin-top: 5vh;
  margin-bottom: 5vh;
}

.home-sec1-part1-buttons {
  margin-top: 0px;
  display: flex;
}

.home-sec1-part1-btn1 {
  margin-right: 20px;
  width: 156px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  background: white;
  border: 2px solid #F3F4F5;
  border-radius: 35px;
  color: #100F0D;
  cursor: pointer;
  height: 54px;
  overflow: hidden;
  position: relative;
  width: 268px;
  height: 54px;

  &:hover {
    transform: scale(1.1);
  }
}

// .home-sec1-part1-btn1::after {
//   background: #100F0D;
//   content: "";
//   height: 155px;
//   left: -75px;
//   opacity: 1;
//   position: absolute;
//   top: -50px;
//   -webkit-transform: rotate(35deg);
//   transform: rotate(35deg);
//   transition: all .55s cubic-bezier(.19,1,.22,1);
//   width: 50px;
//   z-index: -10;
// }

// .home-sec1-part1-btn1:hover::after{
//   left: 120%;
//   transition: all .55s cubic-bezier(.19,1,.22,1);
//   z-index: 50;
// }

.home-sec1-part1-btn2 {
  width: 268px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  border-radius: 35px;
  background: linear-gradient(341.13deg, #5E3199 261.43%, #5E3199 342.4%);
  color: white;
  cursor: pointer;
  overflow: hidden;
  position: relative;

  &:hover {
    transform: scale(1.1);
  }
}

// .home-sec1-part1-btn2::after {
//   background: white;
//   content: "";
//   height: 155px;
//   left: -75px;
//   opacity: 1;
//   position: absolute;
//   top: -50px;
//   -webkit-transform: rotate(35deg);
//   transform: rotate(35deg);
//   transition: all .55s cubic-bezier(.19,1,.22,1);
//   width: 50px;
//   z-index: -10;
// }

// .home-sec1-part1-btn2:hover::after{
//   left: 120%;
//   transition: all .55s cubic-bezier(.19,1,.22,1);
//   z-index: 50;
// }


.home-sec2-left {
  // padding-left: 5vw;
  width: 26vw;
  height: 100%;
  border-right: 0.5px solid #E5E5E5;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  // align-items: center;
  border-top: 0.5px solid #E5E5E5;
  border-bottom: 0.5px solid #e5e5e5;
}

.home-sec2-right {
  width: 78vw;
  height: 100%;
  border-top: 0.5px solid #e5e5e5;
  border-bottom: 0.5px solid #e5e5e5;
}



.each-side-menu {
  padding-Left: 7vw;
  margin-top: 2vh;
  cursor: pointer;
  font-weight: 400;
  font-size: 25px;
  line-height: 18px;
  color: #1B2443;
  font-family: 'Montserrat';
  height: 4rem;
  display: flex;
  align-items: center;

  &:hover {
    margin-right: 10px;
    transform: scale(1.05);
    -webkit-transform: scale(1.05);
    transition: all .1s ease-in;
  }
}

.menu-selected {
  padding-Left: 7vw;
  margin-top: 2vh;
  cursor: pointer;
  font-weight: 400;
  height: 4rem;
  text-decoration: none;
  font-size: 27px;
  height: 4rem;
  margin-right: 2vw;
  display: flex;
  align-items: center;
  line-height: 30px;
  color: #1B2443;
  font-family: 'Montserrat';

  &:hover {
    margin-right: 10px;
    transform: scale(1.05);
    -webkit-transform: scale(1.05);
    transition: all .1s ease-in;
  }
}

.all-side-menus {
  display: flex;
  flex-direction: column;
  // margin: 3vh 0px;
}

.menu-title {
  padding: 10px 0px 10px 5vw;
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  color: #100F0D;
  cursor: pointer;

  &:hover {
    margin-right: 10px;
    transform: scale(1.05);
    -webkit-transform: scale(1.05);
    transition: all .1s ease-in;
  }

  // background-color: #100F0D;
  // width: 75%;
  // clip-path: polygon(0 0, 100% 1px, 85% 100%, 0% 100%);
}

.left-part1 {
  height: 75%;
  width: 78vw;
  display: flex;
  justify-content: end;
  align-items: center;
}

.new-data-box {
  background-color: #100F0D;
  padding: 25px;
  width: 667px;
}

.new-data-box-title {
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  color: #FFFFFF;
}

.new-data-box-main {
  font-weight: 500;
  font-size: 45px;
  line-height: 55px;
  color: #FFFFFF;
  padding-top: 15px;
}

.new-data-box-button {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #FFFFFF;
  padding-top: 25px;
  cursor: pointer;

  &:hover {
    transform: translateY(-20px);
    transition: all 0.1s ease-in;
    // animation: bounce .7s ease alternate;
  }
}

@keyframes bounce {
  0% {
    transition: all 0.6s ease-in;
    // text-shadow:
    //             0 5px 0 #fff,
    //             0 2px 3px rgba(0, 0, 0, 1);  
  }

  100% {
    transition: all 0.6s ease-in;

    //   text-shadow:  0 50px 0 #fff,
    //                 0 0px 20px rgba(0, 0, 0, .8);
  }
}

.left-main {
  height: 100%;
}

.left-part2 {
  height: 170px;
  width: 78vw;
}

.left-part1 {
  height: calc(100% - 170px);
}

.full {
  height: 100%;
  width: 78vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
}

.sec2 {
  text-align: center;
}

.sec2-button {
  margin-top: 50px;
  margin-right: 50px;
}

.home-sec1-part1-mob-desc {
  display: none;
}

.sub-menu-box {
  display: flex;
  flex-direction: column;
  height: calc(100% - 55px);
  justify-content: space-evenly;
}

.main-logo-box {
  height: calc(35vh - 76px);
  width: 78vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 3vw;
  border-bottom: 0.5px solid #E5E5E5;
}

.main-logo-img {
  width: 212px;
  height: 56.21px;
}

.full-img-box {
  height: calc(35vh - 76px);
  width: 78vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 3vw;
  border-bottom: 0.5px solid #E5E5E5;
  align-items: flex-start;
}

.full-img {
  width: 400px;
}

.all-menus {
  height: 8vh;
  width: 78vw;
  display: flex;
  justify-content: flex-start;
  border-bottom: 0.5px solid #E5E5E5;
  align-items: center;
}

.each-item-menu {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #100F0D;
  padding: 10px 30px;
  margin-left: 3vw;
  border: 0.5px solid #F3F4F5;
  border-radius: 15px;
  cursor: pointer;

  &:hover {
    transform: scale(1.2);
    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
}

.menu-items-box {
  height: 65vh;
  width: 78vw;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
}

.all-sub-menus {
  height: 57vh;
  width: 78vw;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
}

.each-sub-item-desc {
  transition: 0.5s;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #100F0D;
  margin-top: 15px;
}

.zoom2 {
  transition: 0.5s;
  width: fit-content;

  &:hover {
    .check1-text {
      color: #100F0D;
      display: inline-flex;
      margin-left: 9px;
      // margin-top: 5px;
      margin-bottom: 5px;
      font-size: 15px;
      font-weight: 500;
    }

    .clickarrow-img {
      margin-left: 0.2rem;
      margin-top: 0.3rem;
      width: 20px;
    }

    .zoom1 {
      background: #fffbf0;
      height: 40px;
      text-align: center;
      width: 120px;
    }
  }
}

.each-sub-item {
  cursor: pointer;
  height: 250px;
  width: 26vw;
  border-bottom: 0.5px solid #E5E5E5;
  border-right: 0.5px solid #E5E5E5;
  padding: 3vw;
  align-items: center;
  cursor: pointer;
  display: flex;
  // flex-direction: column;
  // justify-content: center;
  // position: relative;
  // overflow: hidden;
}


.each-sub-item1 {
  cursor: pointer;
  height: 250px;
  width: 33.3%;
  border-bottom: 0.5px solid #E5E5E5;
  border-right: 0.5px solid #E5E5E5;
  padding: 0px 3vw;
  // align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.each-sub-item1-box {
  display: flex;
  align-items: center;
}

.contentBx {
  position: absolute;
  bottom: -100%;
  transition: all ease 0.4s;
  padding-right: 3vw;
}

.each-sub-item1:hover {
  .contentBx {
    bottom: 2vh;
  }

  .each-sub-item1-box {
    margin-top: -15vh;
    transition: all ease 0.4s;
  }

}

// .each-sub-item::after {
//   background: #100F0D;
//   content: "";
//   height: 50vh;
//   left: -50vw;
//   opacity: 1;
//   position: absolute;
//   top: -50px;
//   -webkit-transform: rotate(35deg);
//   transform: rotate(35deg);
//   transition: all .55s cubic-bezier(.19,1,.22,1);
//   width: 50px;
//   z-index: -10;
// }

// .each-sub-item:hover::after{
//   left: 120%;
//   transition: all .55s cubic-bezier(.19,1,.22,1);
//   z-index: 50;
// }

.each-sub-item-img {
  width: 35px;
  height: 35px;
}

.each-sub-item-text {
  margin-left: 20px;
  font-weight: 500;
  font-size: 28px;
  line-height: 22px;
  color: #100F0D;
}

.each-sub-item-description {
  font-weight: 400;
  font-size: 14px;
}

.mobile-screens {
  display: none;
}

.founder-main {
  // border-top: 0.5px solid #E5E5E5;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.founder-left {
  margin-top: 5%;
  width: 65%;
  height: 95%;
  padding: 0 5%;
  -webkit-animation: lefttoright .6s ease-in forwards;
  animation: lefttoright .6s ease-in forwards;
}

.founder-right {
  margin-top: 5%;
  width: 35%;
  height: 95%;
  -webkit-animation: righttoleft .6s ease-in forwards;
  animation: righttoleft .6s ease-in forwards;
}

.founder-img {
  height: 100%;
  width: 100%;
}

.founder-title {
  font-weight: 600;
  font-size: 3rem;
  line-height: 49px;
  color: #100F0D;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .back {
    margin-left: 10px;
    cursor: pointer;
    border-radius: 35px;
    background: linear-gradient(341.13deg, #5E3199 261.43%, #5E3199 342.4%);
    width: 97px;
    height: 30px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    text-align: center;
    font-size: 13px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    &:hover {
      transform: scale(1.1);
    }
  }
}

.founder-subtitle {
  font-weight: 400;
  font-size: 1.75rem;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #100F0D;
  margin-bottom: 30px;
}

.founder-desc {
  font-weight: 300;
  font-size: 17px;
  line-height: 38px;
  color: #100F0D;
  height: 65vh;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none !important;
  }
}

@keyframes righttoleft {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }

  25% {
    opacity: 0.25;
    transform: translateX(75px);
  }

  50% {
    opacity: 0.5;
    transform: translateX(50px);
  }

  75% {
    opacity: 0.75;
    transform: translateX(25px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes lefttoright {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }

  25% {
    opacity: 0.25;
    transform: translateX(-75px);
  }

  50% {
    opacity: 0.5;
    transform: translateX(-50px);
  }

  75% {
    opacity: 0.75;
    transform: translateX(-25px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}





@media screen and (min-height:"800px") and (max-height:"1200px") {
  .rowbox {
    height: 150px;
  }

  .eachcirclebox {
    height: 150px;
    width: 150px;
  }

  .eachvertbox {
    width: 150px;
  }

  .eachimgcircle {
    height: 90px;
    width: 90px;
    cursor: pointer;
  }

  .eachimgcircle1 {
    height: 150px;
    width: 150px;
    cursor: pointer;
  }
}

@media screen and (min-width: 901px) and (max-width: 2000px) and (min-height: 400px) and (max-height: 660px) {
  .home-sec2-left {
    overflow-y: scroll;
    height: 100%;
    padding-top: 106px;
  }
}


@media screen and (min-width: 300px) and (max-width: 900px) {
  .modal-body {
    width: 370px;
    max-width: 370px;
  }

  .inputboxsection label {
    width: 100%;
  }

  .inputboxsection input {
    border-radius: 5px;
    border: 1px solid rgba(88, 87, 87, 0.25);
    background: #FFF;
    width: 54px !important;
    height: 51px;
  }

  .eachcirclebox {
    height: 85px;
    width: 85px;
  }

  .mobile-screens {
    display: block;
  }

  .check1-text {
    display: none;
    font-size: 12px !important;
    font-weight: 700 !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    background-clip: text !important;
    text-fill-color: transparent;

  }

  .founderCardSection {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: 8%;
    padding-right: 8%;
    padding-top: 3rem;

    p {
      color: #100F0D;
      font-size: 12px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      margin: 0;
      text-align: center;
    }


    div {
      width: 100%;
    }

    .imgcirle {
      display: block !important;
      border-radius: 15px;
      border: 1px solid #EDEDED;
      background: #FFF;
      width: 100%;
      height: 182px;
      margin-bottom: 1rem;
      flex-shrink: 0;
    }

    h1 {
      color: #100F0D;
      font-size: 18px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 0px;
      padding: 0;
      text-align: center;
    }

    h5 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      line-height: 31px;
      /* or 69% */
      font-size: 30px;
      display: flex;
      align-items: center;
      margin-top: 3rem;
      margin-bottom: 0rem;
      justify-content: center;
      display: flex;
      align-items: center;
      margin-top: 0rem;
      margin-bottom: 0rem;
      justify-content: center;

      background: linear-gradient(341.13deg, #5E3199 261.43%, #5E3199 342.4%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      margin-bottom: 3rem;
      justify-content: center;
      width: 100%;
    }
  }

  .holdingPadmobile {
    display: none;
  }

  .home-sec1-part2-each .textyearly {
    color: #FFF;
    margin-top: 20px;
    text-align: right;
    font-size: 18px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
    position: absolute;
    top: 28px;
    right: 0;
    display: none;
  }

  .founderCardSection .cardsinnerFounder {
    cursor: pointer;
    width: 48%;
    padding-left: 0;
    padding-right: 0;
    justify-content: center;
    height: auto;
    margin-bottom: 1.5rem;
    border: 0px;
  }

  .founderCardSection img {
    width: auto;
    position: absolute;
    right: 0;
    transition: all 0.2s linear;
    bottom: 0;
    height: 91%;
    margin-right: 3%;
  }

  .home-sec1-mobile {
    font-family: "Montserrat";
    overflow-x: hidden;
    margin-top: 30px;
    height: auto;
    width: 100vw;
  }

  .main-logo-box {
    width: 100vw;
    margin-top: 76px;
    align-items: center;
    padding-left: 0px;
  }

  .menu-items-box {
    width: 100vw;
  }

  .each-sub-item {
    width: 50vw;
    padding-left: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .each-sub-item1 {
    width: 100vw;
    height: 40vh;
    padding-left: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 5vw;
  }

  .each-sub-item1-box {
    display: "flex";
    flex-direction: column;
    justify-content: center;
    align-items: "center";
    text-align: center;
  }

  .each-sub-item-img {
    width: 60px;
    height: 60px;
    margin-bottom: 20px;
  }

  .each-sub-item-text {
    margin-left: 0px;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    color: #100F0D;
  }

  .full-img-box {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 0vw;
    margin-top: 76px;
  }

  .full-img {
    width: 300px;
  }

  .all-menus {
    height: 8vh;
    width: 100vw;
    display: -webkit-box;
    justify-content: flex-start;
    border-bottom: 0.5px solid #E5E5E5;
    align-items: center;
    overflow-x: scroll;
    padding: 1vh 10px;
  }

  .each-item-menu {
    padding: 10px 30px;
    margin-left: 30px;
  }

  .all-sub-menus {
    width: 100vw;
  }

  .home-sec1 {
    padding-top: 2rem;
    display: none;
  }

  .home-sec1-part1 {
    height: auto;
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .home-sec1-part1-left {
    height: auto;
    width: 100%;
    margin-bottom: 40px;
  }

  .home-sec1-part1-right {
    height: auto;
    width: 100%;
    margin-bottom: 40px;
    align-items: center;
  }

  .home-sec1-part1-title {
    font-weight: 400;
    font-size: 29px;
    line-height: 35px;
    text-align: center;
  }

  .home-sec1-part1-subtitle {
    font-weight: 600;
    font-size: 29px;
    line-height: 34px;
    margin-bottom: 1rem;
    text-align: center;
  }

  .home-sec1-part1-mob-desc {
    display: block;
    font-weight: 300;
    font-size: 16px;
    line-height: 33px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: center;
  }

  .home-sec1-part1-desc {
    display: none;
  }

  .home-sec1-part1-btn1,
  .home-sec1-part1-btn2 {
    padding: 12px 15px;
    font-size: 15px;
    border-radius: 36px;
    height: 46px;
    margin-top: 1rem
  }

  .rowbox {

    justify-content: center;
  }

  .imagesBox,
  .rowbox,
  .linebox {
    width: 90vw;

  }

  .home-sec1-part2 {
    height: auto;
    display: flex;
    flex-direction: column;
    // margin-bottom: 40px;
    width: 100vw;
  }

  .home-sec1-part2-each {
    margin: auto;
    width: 100%;

    padding: 44px 0vw;
    height: auto;
    height: auto;
    margin-bottom: 0vh;
    border-bottom: 1px solid #fff;
    border-radius: 0;

    .textyearly {
      // position: absolute;
      // right: 0;
      // bottom: 5vh;
    }
  }

  .eachvertbox {
    height: 15px;
    width: 130px;
    margin-left: 0px;
  }

  .eachlinev {
    height: 20px;
    width: 15px;
  }

  .eachimgcircle {
    height: 45px;
    width: 45px;
  }

  .home-sec2 {
    display: none;
  }

  .founder-main {
    height: auto;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding: 76px 0px 50px 0px;
    margin: auto;
  }

  .founder-left {
    width: 100%;
    order: 1;
  }

  .founder-title {
    font-size: 2.5rem;
    margin-top: 25px;
    text-align: center;
  }

  .founder-subtitle {
    text-align: center;
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
  }

  .founder-desc {
    text-align: center;
    height: auto;
    overflow: initial;
  }

}