.landing-main-box {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.left-btn-box {
  padding-left: 5vw;
  width: 35vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: start;
  text-align: left;
  font-weight: 600;
  font-size: 35px;
  line-height: 75px;
  color: #100F0D;
  transition: all ease 2s;
  padding-top: 40vh;

  .hover-text {
    color: #100F0D;
  }

  &:hover {
    .hover-text {
      // animation: fadeIn .2s linear;
      // transition: all ease 2s;
      // display: flex;
    }

    // .btns-each{
    //     animation: fadeIn .2s linear;
    //     transition: all ease 2s;
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: space-between;
    // }
  }

  label {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    background: linear-gradient(275.12deg, #277EFB 7.36%, #B580F2 140.3%);
    opacity: 0.9;
    border-radius: 35px;
    justify-content: center;
    width: 162.64px;
    height: 39px;
    margin-top: 1rem;
    align-items: center;
    text-align: center;
    display: none;
    margin: auto;
    margin-top: 1rem;
    color: #FFFFFF;
  }

}

.right-btn-box {
  padding-right: 5vw;
  width: 35vw;
  height: 100vh;
  background: #F5F5F5;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: end;
  text-align: right;
  font-weight: 600;
  font-size: 35px;
  line-height: 75px;
  color: #100F0D;
  transition: all ease 2s;
  padding-top: 40vh;

  .hover-text1 {
    color: #100F0D;
  }

  &:hover {
    .hover-text1 {
      // animation: fadeIn .2s linear;
      // transition: all ease 2s;
      // display: flex;
    }

    // .btns-each1{
    //     animation: fadeIn .2s linear;
    //     transition: all ease 2s;
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: space-between;
    // }

  }

  label {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    background: linear-gradient(275.12deg, #277EFB 7.36%, #B580F2 140.3%);
    opacity: 0.9;
    border-radius: 35px;
    justify-content: center;
    width: 162.64px;
    height: 39px;
    margin-top: 1rem;
    align-items: center;
    text-align: center;
    display: none;
    color: #FFFFFF;
    margin: auto;
    margin-top: 1rem;
  }
}

.main-img-box {
  width: 30vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, #FFFFFF 50%, #F5F5F5 50%);
}

.main-img-icon {
  height: 350px;
  width: 350px;
}

.btns-each {
  transition: all ease .6s;
  height: 60vh;
  padding-bottom: 10vh;
  width: 100%;
  animation: fadeIn .2s linear;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: start;
  text-align: left;
  justify-content: space-between;
}


.btns-each1 {
  transition: all ease .6s;
  height: 60vh;
  padding-bottom: 10vh;
  justify-content: space-between;
  width: 100%;
  animation: fadeIn .2s linear;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: end;
  text-align: right;
}

.btn-img-box {
  cursor: pointer;
  height: 70px;
  width: 70px;

  &:hover {
    transform: scale(1.1);
    transition: all ease .3s;
  }
}

.btn-img-icon {
  height: 70px;
  width: 70px;
}


/// spin

@-webkit-keyframes rotating

/* Safari and Chrome */
  {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(190deg);
    -o-transform: rotate(190deg);
    transform: rotate(190deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(190deg);
    -moz-transform: rotate(190deg);
    -webkit-transform: rotate(190deg);
    -o-transform: rotate(190deg);
    transform: rotate(190deg);
  }
}

.rotating {
  -webkit-animation: rotating 1s linear;
  -moz-animation: rotating 1s linear;
  -ms-animation: rotating 1s linear;
  -o-animation: rotating 1s linear;
  animation: rotating 1s linear;
}


// fade in

@keyframes fadeIn {

  // 0% { opacity: 0; }
  // 50% { opacity: 0.5; }
  // 100% { opacity: 1; }
  0% {
    opacity: 0;
  }

  // 25%{opacity: .25;}
  // 50%{opacity: .50;}
  // 75%{opacity: .75;}
  100% {
    opacity: 1;
  }
}

@media only screen and (min-width: 0px) and (max-width: 900px) {
  .type-name {
    text-align: center;
  }

  .left-btn-box {
    padding: 0;
    padding-left: 8%;
    padding-right: 8%;
  }

  .right-btn-box {
    padding: 0;
    padding-left: 8%;
    padding-right: 8%;

    label {
      display: flex;

    }
  }

  .left-btn-box {
    label {
      display: flex;
    }

  }

  .landing-main-box {
    display: block;
    flex-direction: column;
    overflow: scroll;
  }

  .left-btn-box,
  .right-btn-box {
    height: 25rem;
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    // overflow: scroll;
  }

  .btns-each {

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    text-align: center;
  }

  .btns-each1 {
    text-align: center;
    padding: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    text-align: center;
  }

  .main-img-box {
    height: 15vh;
    width: 100vw;
    background: linear-gradient(180deg, #FFFFFF 50%, #F5F5F5 50%);
  }

  .btn-img-box {
    display: none;
  }

  .main-img-icon {
    height: 100%;
  }

  .btns-each,
  .btns-each1 {
    height: 42.5vh;
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  // .type-name{
  //     width: 65vw;
  // }

  .btn-img-box {
    height: auto;
    width: auto;
    margin-left: 25px;
  }

  .btn-img-icon {
    height: 35px;
    width: 35px;
  }
}

@media only screen and (min-width: 901px) and (max-width: 1200px) {

  .main-img-icon {
    height: 250px;
    width: 250px;
  }

  .right-btn-box,
  .left-btn-box {
    font-size: 30px;
  }
}

.hover-text {
  transition: all ease .6s;
  display: flex;
  font-weight: 300;
  font-size: 18px;
  line-height: 35px;
  margin-top: 10px;
}

.hover-text1 {
  transition: all ease .6s;
  display: flex;
  font-weight: 300;
  font-size: 18px;
  line-height: 35px;
  margin-top: 10px;
}



// base
.flip {
  position: relative;
  overflow: hidden;

  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 45px;
    /* or 129% */

    margin-bottom: 0px;
    text-align: center;
    color: #ffffff;
  }

  P {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;

    line-height: 19px;
    /* or 225% */
    margin-bottom: 0;
    text-align: center;

    margin: 0;
    margin-top: 5px;
    color: #ffffff;
  }

  h2 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 45px;

    display: flex;
    align-items: center;

    color: #212224;
  }

  h4 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 30px;
    /* or 176% */
    margin-bottom: 1rem;
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #100F0D;
  }

  label {
    font-style: normal;
    font-weight: 600;
    font-weight: 600;
    font-size: 19px;
    /* or 221% */
    background: #4caf50;
    border-radius: 15px;
    height: 54px;
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    text-align: center;
    height: 54px;
    width: 90%;
    margin: auto;
    left: 0;
    border-radius: 35px;
    color: #ffffff;
    transition: all ease 0.4s;

    &:hover {
      transform: scale(1.1);
      transition: all ease 0.4s;
    }
  }

  h6 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    /* or 225% */

    color: #100F0D;
  }

  >.front,
  >.back {
    display: block;
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition-duration: 0.5s;
    transition-property: transform, opacity;
  }

  >.front {
    //transform: rotateY(0deg);
  }

  >.back {
    position: absolute;
    opacity: 0;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: none;
    // transform: rotateY(-180deg);
  }

  &:hover {
    >.front {
      cursor: pointer;
      display: none;
    }

    >.back {
      opacity: 1;
      display: flex;
      cursor: pointer;
    }
  }

  &.flip-vertical {
    >.back {
      display: none;
    }

    &:hover {
      >.front {
        display: none;
      }

      >.back {
        display: flex;
      }
    }
  }
}

// custom
.flip {
  position: relative;
  display: flex;
  // margin-right: 2px;

  width: 25%;
  height: 100vh;

  // padding-top: 72px;
  .front {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-content: flex-end;
    background-size: cover !important;
    background-position: center !important;
    height: 100%;
    background-color: #4d4c4cc4;
    background-blend-mode: multiply;
    width: 100%;
    padding-bottom: 2rem;
    padding-left: 8%;
    padding-right: 8%;

    img {
      margin-bottom: 1rem;
      position: absolute;
      text-align: center;
      top: 50%;
      left: 50%;
      transform: translateX(-62%) translateY(-58%);
    }
  }

  >.back {
    display: block;
    //color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-size: cover !important;
    background-position: center !important;
    height: 100%;
    padding-bottom: 5rem;
    padding-left: 8%;
    padding-right: 8%;
    background: #fff;
    width: 100%;

    .backImg {
      margin-bottom: 1rem;
    }

    .about-secton {
      // display: flex;
      align-items: center;
      position: absolute;

      bottom: 2rem;
      width: 100%;

      h3 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 0;
        color: #212224;
      }

      .box {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        background: rgba(40, 55, 144, 0.95);
        border-radius: 5px;
        margin-left: 10px;

        img {}
      }
    }

    p {
      font-size: 0.9125rem;
      line-height: 160%;
      color: #999;
    }
  }
}

.innerbannerLanding {
  height: 59px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 3.5%;
  justify-content: space-between;
  padding-right: 3.4%;
  background: linear-gradient(180deg, #283790 0%, #000000 147.45%);

  h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: right;
    text-decoration-line: underline;

    color: #ffffff;
    margin-bottom: 0px;
  }

  h6 {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    display: flex;
    align-items: center;
    margin-bottom: 0;

    color: #ffffff;
  }
}

.flipCard {
  width: 50% !important;

  .front {
    padding-left: 6% !important;
  }

  .back {
    padding-left: 6% !important;
  }
}

.cardSection {
  display: flex;
  background: black;
  align-items: center;
  border-bottom: 0.5px solid #EDEDED;
}

@media only screen and (max-width: 991.9px) {
  .innerbannerLanding {
    h5 {
      display: none;
    }
  }

  .landingpage {
    height: 100vh;
    position: relative;

    h6 {
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 34px;
      display: flex;
      align-items: center;

      color: #ffffff;
    }

    h5 {
      display: none;
    }

    h1 {
      margin-bottom: 150px;
    }

    span {
      font-style: normal;
      font-weight: 600;
      font-size: 41px;
      line-height: 55px;
      display: flex;
      align-items: center;
      width: 100%;
      color: #ffffff;
    }

    .props-object-landling {
      display: block;
      align-items: center;
      width: 93.2%;
      position: absolute;
      bottom: 2rem;

      justify-content: space-between;

      label {
        width: 100%;
        font-weight: 600;
        font-size: 20px;
        margin-top: 1.5rem;
      }
    }
  }

  .Products {
    padding-top: 68px;
  }

  .cardSection {
    display: none;
    background: black;
    align-items: center;


  }

  .timeline-full {
    margin-top: 7.5px;
    float: right;
    width: 100%;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;

    // display: none;
    .innsecardtimeline {
      width: 50%;
    }
  }

  .time-year {
    margin-top: 11px;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #FFFFFF;
  }

  .time-name {
    font-weight: 500;
    font-size: 17px;
    line-height: 15px;
    color: #FFFFFF;
  }

  .flip {
    position: relative;
    display: flex;
    width: 100%;
    height: calc(100vh - 68px);
    padding-top: 0px;

    .front {
      padding-left: 4%;
    }

    .back {
      padding-left: 4%;
    }
  }

  .flipCard {
    width: 100% !important;

    .front {
      padding-left: 4% !important;
    }

    .back {
      padding-left: 4% !important;
    }
  }
}

.drawer {
  background: white;
  position: fixed;
  z-index: 999;
  height: 100vh;
  width: 28%;
  transition: all ease 0.4s;
  transform: translateX(-100%);
  border-right: 1px solid #EDEDED;
  padding-left: 8%;

  img {
    padding-top: 18px;
    transition: all ease 0.4s;
    cursor: pointer;

    &:hover {
      transform: scale(1.1);
      transition: all ease 0.4s;
    }
  }

  .inner {
    margin-top: 17px;
    display: flex;
    justify-content: center;
    align-items: self-start;
    flex-direction: column;
    height: 100%;
  }

  h1 {
    color: #100F0D;
    font-size: 35px;
    font-family: Montserrat;
    font-weight: 300;
    margin-bottom: 4vh;
    transition: all ease 0.4s;
    cursor: pointer;

    &:hover {
      transform: scale(1.1);
      transition: all ease 0.4s;
    }
  }
}

.drawertrue {
  background: white;
  position: fixed;
  z-index: 999;
  height: 100vh;
  width: 28%;
  transition: all ease 0.4s;
  transform: translateX(0%);
  border-right: 1px solid #EDEDED;
  padding-left: 8%;
  padding-right: 8%;

  .innsideflex {
    width: 100%;
    display: none;
  }

  .mobilegetStart {
    display: none;
  }

  .mobiletracker {
    display: none;
  }

  .mobileinside {
    width: 100%;
    margin-top: 1rem;
    display: none;
  }

  img {
    padding-top: 18px;
    transition: all ease 0.4s;
    cursor: pointer;

    &:hover {
      transform: scale(1.1);
      transition: all ease 0.4s;
    }
  }

  .inner {
    margin-top: 17px;
    display: flex;
    justify-content: center;
    align-items: self-start;
    flex-direction: column;
    height: 100%;
  }

  .innsideflex {
    width: 100%;
    // display: block;
  }

  .desktopland {
    display: block;
    width: 100%;
  }

  h1 {
    color: #100F0D;
    font-size: 30px;
    font-family: Montserrat;
    font-weight: 300;
    margin-bottom: 3vh;
    border-radius: 50px;
    transition: all ease 0.4s;
    padding-top: 10px;
    cursor: pointer;

    &:hover {
      transform: scale(1.1);
      transition: all ease 0.4s;
    }
  }
}

.navlogo {
  background: white;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  height: 71px;
  z-index: 9;

  border-bottom: 5px solid #5E3199;
  background: #fff;
  border-left-width: 0;
  border-right-width: 0;
  border-image: linear-gradient(341.13deg, #5E3199 261.43%, #5E3199 342.4%) 1 stretch;

  // background: linear-gradient(341.13deg, #5E3199 261.43%, #5E3199  342.4%);
  .inner {
    max-width: 1300px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
  }

  .img {
    cursor: pointer;
    position: absolute;
    left: 3%;
    transition: all ease 0.4s;
    display: none;

    &:hover {
      transition: all ease 0.4s;
      transform: scale(1.1);
    }
  }

  .super {
    color: #FFF;
    text-align: center;
    font-size: 13px;
    font-family: Montserrat;
    font-weight: 500;
    border-radius: 35px;
    background: #fff;
    width: 167px;
    height: 37px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    right: 0%;
    border: 1px solid #EFEFEF;
    border-radius: 50rem;
    transition: all ease 0.4s;

    &:hover {
      transition: all ease 0.4s;
      transform: scale(1.1);
    }
  }
}


@media only screen and (max-width: 991.9px) {
  .drawer {
    width: 100%;
    padding-left: 8%;
  }

  .drawertrue {
    background: white;
    position: fixed;
    z-index: 999;
    height: calc(100vh - 48px);
    width: 100%;
    transition: all ease 0.4s;
    transform: translateX(0%);
    padding-top: 0rem;
    padding-left: 0%;
    padding-right: 0;
    top: 71px;

    img {
      display: none;
    }

    .innsideflex {
      display: flex;
      align-items: center;
      overflow: scroll;
      width: 100%;

      border-bottom: 1px solid #EDEDED;
      padding-left: 8%;
      padding-right: 8%;
      padding-top: 0.6rem;
      padding-bottom: 0.6rem;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .desktopland {
      display: none;
      width: 100%;
    }

    .inner {
      margin-top: 0px;
      display: flex;
      justify-content: center;

      justify-content: flex-start;
      flex-direction: column;
      height: 100%;
    }

    .mobiletracker {
      color: #fff;
      text-align: center;
      font-family: Montserrat;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      border-radius: 35px;
      height: 55.217px;

      width: 93%;
      left: 3%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 1.2rem;

      margin: auto;
      border-radius: 35px;
      background: linear-gradient(341.13deg, #5E3199 261.43%, #5E3199 342.4%);
    }

    .mobilegetStart {
      color: #100F0D;
      text-align: center;
      font-family: Montserrat;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      border-radius: 35px;
      height: 55.217px;

      width: 93%;
      left: 3%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 5.5rem;

      margin: auto;
      border: 1px solid #EDEDED;
    }

    .mobileinside {
      width: 100%;
      margin-top: 1rem;
      display: block;
    }

    h5 {
      color: #100F0D;
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding-left: 8%;
      padding-bottom: 0.7rem;
      padding-top: 0.7rem;
      border-radius: 10px;
      margin-bottom: 2vh;

    }

    h1 {
      color: #100F0D;
      font-size: 27px;
      font-family: Montserrat;
      font-weight: 300;
      margin-bottom: 0vh;
      transition: all ease 0.4s;
      margin-right: 16px;
      cursor: pointer;
      color: #100F0D;
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      padding: 0.5rem 1.4rem;
      border-radius: 50px;

      &:hover {
        transform: scale(1);
      }

    }
  }

  .navlogo {

    background: white;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      margin-right: 0;

    }

    .img {
      position: static;
      margin-right: 11px;
      display: block;
    }

    .mobileimg {
      height: 20px;
      display: none;

    }

    .super {
      position: static;
      // display: none;
      width: 125px;
    }
  }
}