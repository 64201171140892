@import "../../Static//scss/colours";
@import "../../Static/scss/mixin";

.patnershipMaster{
  // padding-top: ;
  height: 100%;
  width: 100%;
  color: #100F0D;
  overflow: hidden;
  display: flex;

}
.selectoptions{
  height: 100%;
  width: 40%;
  border-right: 0.5px solid #EFEFEF;
}
.abcd{
  padding-top: 45px;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .pMaintitle{
    font-weight: 500;
    font-size: 30px;
    line-height: 45px;
    padding: 0px 60px 0 60px;
  }
}
.partnershipSection{
  
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  padding: 0px 60px 0 60px;
  .psubhead{
    padding-top: 40px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    
  }
  .ptextbox{
    border: 0.5px solid #EFEFEF;
    padding-left: 15px;
    height: 60px;
    border-radius: 35px;
    display: flex;
    // justify-content: center;
    align-items: center;
  }

  .ptextbox1{
    border: 0.5px solid #EFEFEF;
    padding: 20px;
    height: 280px;
    border-radius: 35px;
    display: flex;
    // justify-content: center;
    // align-items: center;
  }
  .pinput{
    border: none;
    height: 40px;
    width: 100%;
    border-radius: 35px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
  .ptextarea{
    resize: none;
    border: none;
    height: 280px;
    width: 100%;
    border-radius: 7px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
  .pinput .ptextarea::placeholder {
    opacity: 0.35;
  }
  
  .pinput .ptextarea:-ms-input-placeholder { /* Internet Explorer 10-11 */
      opacity: 0.35;
  }
    
  .pinput .ptextarea::-ms-input-placeholder { /* Microsoft Edge */
      opacity: 0.35;
  }

  /* Chrome, Safari, Edge, Opera */
  .pinput::-webkit-outer-spin-button,
  .pinput::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  .pinput[type=number] {
    -moz-appearance: textfield;
  }

  .multiselectbox{
    display: flex;
    flex-wrap: wrap;
  }

  .eachselect{
    cursor: pointer;
    width: 47%;
    height: 80px;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: center;
    border: 0.5px solid #EFEFEF;
    // padding: 15px;
    border-radius: 35px;
    margin: 10px;
  }
  .selectedbox{
    border: 2px solid #F2A900;
  }
  .cardtitle{
    height: 80px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .finalsubmit{
    width: 226px;
    height: 50px;
    margin-top: 40px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    background: linear-gradient(341.13deg, #5E3199 261.43%, #5E3199  342.4%);
    border-radius: 35px;
    cursor: pointer;
    &:hover{
      transition: all ease 0.4s;
      transform: scale(1.1);
    }
  }
  }

.pagemask {
  position: fixed !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 11;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  overflow: hidden !important;
}

.finalBox{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.confirmationbox{
  height: 100%;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.endbox{
  background-color: white;
  height: 50%;
  width: 80%;
  border-radius: 35px;
}
.imgbox{
  height: 20%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.otclogo{
  height: 100%;
  width: 350px;
}
.textbox{
  padding: 70px;
  height: 60%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.confirmtext{
  font-weight: 500;
  font-size: 18px;
  line-height: 40px;
}

.buttonBox{
  height: 20%;
  width: 100%;
  display: flex;
  font-weight: 500;
  font-size: 20px;
  line-height: 40px;
  bottom: 0;
  border-top: 0.5px solid #EFEFEF;
}

.homebtn{
  width: 50%;
  background-color: white;
  color: #100F0D;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 35px;
  text-align: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  @include btn-animate(#100F0D);
}


.inquiry{
  width: 50%;
  // background-color: #F2A900;
  background: linear-gradient(341.13deg, #5E3199 261.43%, #5E3199  342.4%);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-bottom-right-radius: 35px;
  cursor: pointer;
  @include btn-animate(white);
}




.rowStyle{
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  div{
    width: 96%;
  }
}





// Media Query







@media only screen and (max-width: 600px) {
  .abcd{
    padding-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .pMaintitle{
      font-weight: 500;
      font-size: 30px;
      line-height: 45px;
      padding: 0px 20px 0 20px;
    }
  }
  .partnershipSection{
  
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    padding: 0px 20px 0 20px;
    .psubhead{
      padding-top: 40px;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      
    }
    .ptextbox{
      border: 0.5px solid #EFEFEF;
      padding: 15px;
      border-radius: 35px;
    }
    .pinput{
      border: none;
      height: 40px;
      width: 100%;
      border-radius: 35px;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }
    .ptextarea{
      resize: none;
      border: none;
      height: 140px;
      width: 100%;
      border-radius: 35px;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }
    .pinput .ptextarea::placeholder {
      opacity: 0.35;
    }
    
    .pinput .ptextarea:-ms-input-placeholder { /* Internet Explorer 10-11 */
        opacity: 0.35;
    }
      
    .pinput .ptextarea::-ms-input-placeholder { /* Microsoft Edge */
        opacity: 0.35;
    }
  
    /* Chrome, Safari, Edge, Opera */
    .pinput::-webkit-outer-spin-button,
    .pinput::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  
    /* Firefox */
    .pinput[type=number] {
      -moz-appearance: textfield;
    }
  
    .multiselectbox{
      display: flex;
      flex-wrap: wrap;
    }
  
    .eachselect{
      cursor: pointer;
      width: 44%;
      height: 80px;
      // display: flex;
      // flex-direction: column;
      // align-items: center;
      // justify-content: center;
      border: 0.5px solid #EFEFEF;
      // padding: 15px;
      border-radius: 7px;
      margin: 10px;
    }
    .selectedbox{
      border: 2px solid #F2A900;
    }
    .cardtitle{
      height: 80px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .finalsubmit{
      width: 226px;
      height: 66px;
      margin: 20px 0;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: white;
      background: linear-gradient(341.13deg, #5E3199 261.43%, #5E3199  342.4%);
      border-radius: 35px;
      cursor: pointer;
      &:hover{
        transition: all ease 0.4s;
        transform: scale(1.1);
      }
    }
    }
    .rowStyle{
      display:flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 20px;
      div{
        width: 96%;
      }
    }
}

