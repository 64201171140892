.topdata {
    display: flex;
}

.main-hold {
    display: flex;
    text-align: left;
    width: 25%;
}

.holdingshead {
    margin: 30px;
}

.title-hold {
    font-weight: 400;
    font-size: 17px;
    color: #100F0D;
}

.num-hold {
    font-weight: 500;
    font-size: 40px;
    color: #100F0D;
    margin-top: -20px;
}

.vl-hold {
    border-left: 0.5px solid #E5E5E5;
    height: 159px;
    margin-left: auto;
}

.datachange {
    display: flex;
    margin-top: -20px;
}

.green-arrow {
    margin-top: -17px;
    margin-right: 5px;
}

.num-chng {
    font-weight: 500;
    font-size: 13px;
    color: #0E9347;
}

.chng-val {
    font-weight: 400;
    font-size: 13px;
    color: #100F0D;
    margin-left: 4px;
}

.right-hold {
    width: 75%;
    text-align: left;
    display: flex;
}

.pie-width {
    width: 120px;
    margin-left: 60px;
    margin-top: -14px;
}

.group-cards {
    display: flex;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
}

.share-card {
    width: 22%;
    border: 0.5px solid #E7E7E7;
    border-radius: 15px;
    height: 75px;
    margin-left: 34px;
    border-left: 15px solid #5E3199 ;
    margin-top: -20px;
}

.tax-text {
    display: flex;
    padding: 15px;
}

.textcard {
    font-weight: 500;
    font-size: 12px;
    color: #100F0D;
}

.tax-icon {
    margin-top: -18px;
    margin-right: 4px;
}

.share-num {
    display: flex;
    margin-top: -25px;
    margin-left: 15px;
    align-items: center;
}

.share-val {
    font-weight: 500;
    font-size: 15px;
    color: #100F0D;
}

.share-chng {
    font-weight: 400;
    font-size: 10px;
    color: #100F0D;
    margin-left: 5px;
}

.hr-port-sty {
    margin-top: -15px;
    border: 0.5px solid #E5E5E5;
}

.hr-btm-sty {
    border: 0.5px solid #E5E5E5;
    width: 100%;
}

.chart-disp {
    width: 100%;
    padding-right: 30px;
}

.time-frame {
    display: flex;
    float: right;
}

.num-frame {
    margin-right: 20px;
    font-weight: 400;
    font-size: 12px;
    text-align: right;
    color: #100F0D;
    cursor: pointer;
}

.num-active {
    font-weight: 500;
}

.num-right {
    margin-right: 50px;
}

@media screen and (max-width:1200px) {
    .group-cards{
        justify-content: flex-start;
    }
    
}