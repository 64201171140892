.timeline-full{
    margin-top: 7.5px;
    float: right;
    width: 100%;
    // height: 170px;
    // overflow-x: scroll;
    // margin: 20vh 0px 20px 0px;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    // height: 40vh;
    .innsecardtimeline{
        width: 25%;
    }
}

.timeline-each{
    display: flex;
    width: 100%;
    height: calc(100vh - 76px);
}

.timeline-each-left{
    width: 30%;
    height: 100%;
    cursor: pointer;
}

.timeline-each-right{
    width: 70%;
    height: 100%;
    padding: 0px 5%;
}

.each-right-name{
    font-weight: 600;
    font-size: 40px;
    line-height: 49px;
    display: flex;
    align-items: center;
    color: #100F0D ;
    margin: 25px 0px;
}

.each-right-text{
    font-weight: 400;
    font-size: 25px;
    line-height: 30px;
    display: flex;
    align-items: center;
    color: #100F0D ;
    margin-bottom: 25px;
}

.each-right-desc{
    font-weight: 300;
    font-size: 17px;
    line-height: 38px;
    color: #100F0D ;
    height: calc(80%);
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
}

.each-right-singledesc{
    margin-bottom: 20px;
}

.present{
    height: 15px !important;
    width: 15px !important;
    background: linear-gradient(90deg, rgb(240, 92, 77) 0%, rgb(181, 128, 242) 100%) !important;
    border-radius:50% !important;
    cursor:pointer !important;
    border: 3px solid #FFFFFF;
}

.present-line{
    background:#100F0D !important;
}

.time-name{
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;    
    color: #FFFFFF;
}

.time-year{
    margin-top: 16px;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #FFFFFF;
}

.timebox:hover, .timeline-each-left:hover{
    .time-name, .time-year{
        transform: scale(1.1);
        transition: all ease .3s;
    }
}

.mark {
    -webkit-animation: .2s highlight .2s 1 normal forwards;
            animation: .2s highlight .2s 1 normal forwards;
    background-color: none;
    background: linear-gradient(90deg, #100F0D 50%, rgba(0, 0, 10, 0) 50%);
    background-size: 200% 100%;
    background-position: 100% 0;
  }


@keyframes highlight {
    to {
      background-position: 0 0;
    }
  }



  .TimelinePage{
    height: calc(100vh - 76px);
    h1{
        color: #FFF;
text-align: center;
font-family: Montserrat;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-bottom: 0;
    }
    .inndetextchnage{
        width: 25%;
        color: #FFF;
text-align: center;
font-family: Montserrat;
font-size: 25px;
font-style: normal;
font-weight: 600;
line-height: normal;
height: 100%;
flex-shrink: 0;
background: linear-gradient(341.13deg, #5E3199 261.43%, #5E3199  342.4%);
align-items: center;
display: flex;
justify-content: center;
position: relative;
padding-left: 1.3rem;
img{
    position: absolute;
left: 0;

}
    }
    .insidetect
    {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        bottom: -20px;
        width: 100%;
        z-index: 1;
        // position: relative;
      

    }
    .topSectioninnerprops{
        height: 50%;
        // background-image: url(/static/media/topback.ba0e8253.svg);
        // background-repeat: no-repeat;
        background-size: cover !important;
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
    }
    .showinside{
        display: flex;
        width: 100%;
        height: 50%;
    }
    .innershowtime{
        height: 100%;
        width: 75%;
    }
    .line{
        background: #5E3199 ;
      //  linear-gradient(341.13deg, #5E3199 261.43%, #5E3199  342.4%);
        width: 100%;
        height: 3px;
        // position: absolute;
        width: 100%;
    }
    .mar{
        margin-top: 16px;
    }
    .text{
        color: #100F0D;
text-align: center;
font-family: Montserrat;
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: normal;
width: 120px;
height: 37px;
display: flex;
align-items: center;
justify-content: center;
flex-shrink: 0;
background-color: white;
border-radius: 35px;
cursor: pointer;
    }
    .bcktint{
        background-color: rgba(37,33,29,0.5) !important;
        background-blend-mode: multiply;
    }
    .topSectioninnertime{
        height: 25%;
        background-image: url('../../Static/topback.svg');
        background-repeat: no-repeat;
        background-size: cover !important;
        position: relative;
        display: flex;
    align-items: center;
    }
    .cardinside{
        width: 25%;
        position: relative;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
    &:hover{
        h1{
            transform: scale(1.05);
        }
    }
    }
    .cardinsideinside{
        width: 33.33%;
        position: relative;
    height: 100%;
    align-items: center;
    display: flex;
    cursor: pointer;
    justify-content: center;
    }
  }

  @media screen and (min-width: 300px) and (max-width: 900px) {


    .TimelinePage

    {
        height: auto;

        .showinside {
            display: flex;
            width: 100%;
            height: auto;
        }
        .topSectioninnerprops {
            height: auto;
            display: flex;
        }
        .inndetextchnage {
            width: 25%;
            color: #FFF;
            text-align: center;
            font-family: Montserrat;
            font-size: 8px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            height: 100%;
            flex-shrink: 0;
            background: linear-gradient(341.13deg, #5E3199 261.43%, #5E3199  342.4%);
            align-items: center;
            display: flex;
            justify-content: center;
            position: relative;
            padding-left: 1.3rem;
            height: 18rem;
        }
        .innershowtime {
            height: auto;
            // width: 100%;
        }
        .topSectioninnertime {
            height: auto;
            flex-wrap: wrap;
        }
        .cardinsideinside {
            width: 33.33%;
            position: relative;
            height: 9rem;
            align-items: center;
            display: flex;
            justify-content: center;
        }
        .insidetect {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: absolute;
            bottom: -13px;
            width: 100%;
            z-index: 1;
        }
        .text {
            color: #100F0D;
            text-align: center;
            font-family: Montserrat;
            font-size: 11px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            width: 69px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            background-color: white;
            border-radius: 35px;
        }
        .cardinside {
            width: 25%;
            height: 9rem;

        }
        h1 {
            color: #FFF;
            text-align: center;
            font-family: Montserrat;
            font-size: 11px;
        }


    }


  }