@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap");
html {
  scroll-snap-type: y mandatory;
}

p {
  font-family: Montserrat;
}
a {
  font-family: Montserrat;
}
h1 {
  font-family: Montserrat;
}
h2 {
  font-family: Montserrat;
}
h3 {
  font-family: Montserrat;
}
h4 {
  font-family: Montserrat;
}
h5 {
  font-family: Montserrat;
}
div {
  font-family: Montserrat;
}
h6 {
  font-family: Montserrat;
}

label {
  font-family: Montserrat;
}
body {
  font-family: Montserrat;
}
span {
  font-family: Montserrat;
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
}
