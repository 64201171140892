.Mediapage {
    height: auto;

    padding-top: 122px;
    max-width: 1300px;
    margin: auto;
    // overflow: scroll;

    h1 {
        color: #100F0D;
        font-family: Montserrat;
        font-size: 35px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        background: linear-gradient(341.13deg, #5E3199 261.43%, #5E3199 342.4%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        text-align: center;
        margin-bottom: 0;
    }

    h2 {
        color: #100F0D;
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        text-align: center;
        line-height: 35px;
    }

    .cardsection {
        display: flex;
        align-items: center;
        justify-content: start;
        padding-top: 2rem;
        flex-wrap: wrap;
        gap: 2%
    }

    .card {
        width: 32%;
        margin-bottom: 4.3vh;
        height: 375px;
        flex-shrink: 0;
        border: 1px solid #EFEFEF;
        background: #FFF;
        cursor: pointer;
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        border-radius: 25px;
        background-repeat: no-repeat !important;
        background-size: cover !important;

        p {
            color: #fff;
            font-family: Montserrat;
            font-size: 25px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: #fff;
            font-family: Montserrat;
            font-size: 25px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            /* margin-bottom: 3rem; */
            /* margin-left: 35px; */

            width: 100%;
            margin-bottom: 0;
            height: 4.5rem;
            display: flex;
            align-items: center;
            padding-left: 35px;
            border-radius: 0px 0px 25px 25px;
            background: rgba(22, 23, 26, 0.71);
        }

        &:hover {
            transform: scale(1.01);
        }
    }
}