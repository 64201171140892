.wholetable {
    position: relative;
    border-collapse: collapse;
}


.react-parallax {

    img {
        height: 100vh !important;
        width: 100% !important;
        filter: none;
        object-fit: cover;
    }
}

._1X2N_{
    display: block !important;
}
thead th {
    position: -webkit-sticky;
    /* for Safari */
    position: sticky;
    top: 0;
    background-color: #FFFFFF;
}

thead th:first-child {
    left: 0;
    z-index: 1;
}

tbody th {
    position: -webkit-sticky;
    /* for Safari */
    position: sticky;
    left: 0;
    background: #FFF;
}

tr {
    height: 100px;
}

.tabmenubox1 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.tableNavdiv1 {
    width: 250px;
    border-right: 1px solid #EFEFEF;
    border-bottom: 1px solid #EFEFEF;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 30px;
}

.tablemainimg {
    height: 100px;
    width: 150px;
}

.mobtablemaintext {
    display: none;
}

.eachtablesidemenu {
    display: flex;
    padding-left: 30px;
    align-items: center;
    border-right: 1px solid #EFEFEF;
    height: 100px;
    border-bottom: 1px solid #EFEFEF;
    width: 284px;
}

.tabmenubox1 {
    border-right: 1px solid #EFEFEF;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #EFEFEF;
}



.maintable {
    background-color: #FFFFFF;

    height: calc(100% - 101px);
    width: 100%;
    border-radius: 30px;
    overflow: scroll;

}

/// t
.tablemenutitle{
    font-weight: 500;
}
.columnhead {
    width: 220px;
    height: 70px;
    border-right: 1px solid #EFEFEF;
    border-bottom: 1px solid #EFEFEF;
    border-top: 1px solid #EFEFEF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-weight: 500;
}



.columnheadempty{
    width: 284px;
    height: 70px;
    border-right: 1px solid #EFEFEF;
    border-bottom: 1px solid #EFEFEF;
    border-top: 1px solid #EFEFEF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}




















.custom-scroll {
    position: absolute;
    width: 1px;
    top: 2px;
    right: 0;

}















.Staticcolor {
    background-color: #100F0D;
    opacity: 0.5;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #100F0D;
    opacity: 0.5;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    left: 0;
}

.Staticlogo {
    width: 238px;
    position: absolute;
    z-index: 4;
    left: 128px;
    top: 53px;
}

a[href='#Our Roadmap'] {
    display: none
}

.bottom-content-right {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 80px;
    /* or 200% */
    bottom: 5rem;
    position: absolute;

    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
    right: 6rem;
    color: #FFFFFF;
    animation: bounce .7s ease infinite alternate;
    cursor: pointer;

    span {
        font-family: 'Montserrat';
        font-style: italic;
        font-weight: 500;
        font-size: 30px;
        line-height: 37px;
        display: flex;
        align-items: center;
        margin-left: 4px;
        margin-right: 8px;
        color: #FFFFFF;
    }

    &:hover {
        transform: translateY(-20px);
    }
}

.bottom-content-right1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 80px;
    // /* or 200% */
    // bottom: 5rem;
    // position: absolute;

    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
    // right: 6rem;
    color: #FFFFFF;
    animation: bounce .7s ease infinite alternate;
    cursor: pointer;

    span {
        font-family: 'Montserrat';
        font-style: italic;
        font-weight: 500;
        font-size: 30px;
        line-height: 37px;
        display: flex;
        align-items: center;
        margin-left: 4px;
        margin-right: 8px;
        color: #FFFFFF;
    }

    &:hover {
        transform: translateY(-20px);
    }
}

.bottom-content {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 80px;
    /* or 200% */
    bottom: 5rem;
    position: absolute;

    display: flex;
    align-items: center;
    letter-spacing: 0.1em;

    color: #FFFFFF;

    span {
        font-family: 'Montserrat';
        font-style: italic;
        font-weight: 500;
        font-size: 30px;
        line-height: 37px;
        display: flex;
        align-items: center;
        margin-left: 4px;
        margin-right: 8px;
        color: #FFFFFF;
    }

    //animation: bounce .7s ease infinite alternate;
    cursor: pointer;
    transition: all 0.6s ease-in;

    &:hover {
        transform: translateY(-20px);
    }
}

@keyframes bounce {
    0% {
        transition: all 0.6s ease-in;
        // text-shadow:
        //             0 5px 0 #fff,
        //             0 2px 3px rgba(0, 0, 0, 1);  
    }

    100% {
        transition: all 0.6s ease-in;

        //   text-shadow:  0 50px 0 #fff,
        //                 0 0px 20px rgba(0, 0, 0, .8);
    }
}

.tablemenuimg {
    height: 30px !important;
    width: 30px !important;
    margin-right: 10px;
}

.cardstitle {

    a {
        text-decoration: none;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        display: flex;
        align-items: center;
        width: 296px;
        color: #1B2443;
        // justify-content: center;
        margin-bottom: 3rem;
        cursor: pointer;
        transition: all 0.1s ease-in;

        &:hover {
            color: #1B2443;
            transition: all 0.1s ease-in;
            transform: scale(1.1);
        }
    }
}

.cardstitle {
    text-align: center;
    display: flex;
    padding-left: 69px;
    align-items: center;
    // justify-content: center;
}

.active-nav {

        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700 !important;
        font-size: 19px !important;
        line-height: 30px;
        display: flex;
        align-items: center;

        color: #1B2443;
    
}

.list-story {
    position: fixed;

    text-align: center;
    top: 0;
    /* right: 50%; */
    top: 50%;
    z-index: 1;
    transform: translate(0%, -50%);
    /* left: 23px; */
    text-align: center;

    ul {
        padding: 0 !important;
    }
}

.mainstataic {
    width: 196px;
    margin-bottom: 1rem;
}

.meta-econocy {
    height: 100vh;
    overflow: hidden;
    background-image: url("../Static/images/backgroungNvest.png");

    background-size: cover;
    background-repeat: no-repeat;
    position: relative;


    padding-left: 128px;
    padding-top: 108px;
    align-items: center;
    display: flex;

    .content {
        padding-bottom: 9rem;
        z-index: 4;

        .ered {
            background: white;
            border-radius: 185px;
            position: absolute;

            bottom: 2%;
            right: 0rem;
            width: 10%;

            transform: translate(24%, -248%);
            animation: ease-in-out infinite;
            animation-name: ered;
            animation-duration: 10s;
            cursor: pointer;

            &:hover {
                box-shadow: 0 33px 45px rgba(251, 249, 249, 0.3), 0 0 40px rgba(251, 249, 249, 0.3) inset;

            }

        }

        .elipse {

            border-radius: 185px;
            position: absolute;

            width: 13%;
            bottom: -8%;
            right: 12%;

            animation: ease-in-out infinite;
            animation-name: elipse;
            animation-duration: 10s;
            cursor: pointer;

            &:hover {
                box-shadow: 0 33px 45px rgba(251, 249, 249, 0.3), 0 0 40px rgba(251, 249, 249, 0.3) inset;

            }

        }

        .vchain {

            border-radius: 185px;
            position: absolute;
            bottom: 2%;
            transform: translate(-111%, -66%);
            width: 10%;
            right: 12%;
            animation: ease-in-out infinite;
            animation-name: vchain;
            animation-duration: 10s;

            cursor: pointer;

            &:hover {
                box-shadow: 0 33px 45px rgba(251, 249, 249, 0.3), 0 0 40px rgba(251, 249, 249, 0.3) inset;

            }
        }

        .inr {

            border-radius: 185px;
            position: absolute;
            bottom: 2%;
            right: 12%;
            width: 16%;
            transform: translate(-3%, -107%);
            animation: ease-in-out infinite;
            animation-name: inr;
            animation-duration: 10s;
            cursor: pointer;

            &:hover {
                box-shadow: 0 33px 45px rgba(251, 249, 249, 0.3), 0 0 40px rgba(251, 249, 249, 0.3) inset;

            }
        }

        .global {
            background: white;
            border-radius: 100rem;
            position: absolute;
            position: absolute;
            bottom: 0%;
            right: -4%;
            width: 17%;
            transform: translate(-3%, -39%);
            animation: ease-in-out infinite;
            animation-name: global;
            animation-duration: 10s;
            cursor: pointer;
            transition: all 0.1s ease-in;

            &:hover {
                box-shadow: 0 33px 45px rgba(251, 249, 249, 0.3), 0 0 40px rgba(251, 249, 249, 0.3) inset;

            }

        }


        @keyframes elipse {

            //elips
            0% {

                width: 13%;
                bottom: -8%;
                right: 12%;
                transform: translate(-0%, -0%);
            }

            //inr
            20% {
                right: 12%;
                width: 16%;
                transform: translate(-3%, -107%);
                bottom: 2%;

            }

            //vchain
            40% {

                bottom: 2%;
                transform: translate(-111%, -66%);
                width: 10%;
                right: 12%;

            }

            //global
            60% {
                right: -4%;
                width: 17%;
                transform: translate(-3%, -39%);
                bottom: 0%;
            }


            //ered
            80% {

                bottom: 2%;
                right: 0rem;
                width: 10%;

                transform: translate(24%, -248%);
            }


            //elips
            100% {

                width: 13%;
                bottom: -8%;
                right: 12%;
                transform: translate(-0%, -0%);
            }
        }


        @keyframes ered {

            //ered
            0% {

                bottom: 2%;
                right: 0rem;
                width: 10%;

                transform: translate(24%, -248%);
            }

            //global
            20% {
                right: -4%;
                width: 17%;
                transform: translate(-3%, -39%);
                bottom: 0%;
            }

            //inr
            40% {
                right: 12%;
                width: 16%;
                transform: translate(-3%, -107%);
                bottom: 2%;

            }

            //elipse
            60% {
                width: 13%;
                bottom: -8%;
                right: 12%;
                transform: translate(-0%, -0%);
            }

            //vchain
            80% {

                bottom: 2%;
                transform: translate(-111%, -66%);
                width: 10%;
                right: 12%;

            }

            //ered
            100% {

                bottom: 2%;
                right: 0rem;
                width: 10%;

                transform: translate(24%, -248%);
            }

        }


        @keyframes vchain {

            //vchain
            0% {
                bottom: 2%;
                transform: translate(-111%, -66%);
                width: 10%;
                right: 12%;
            }

            //ered
            20% {
                bottom: 2%;
                right: 0rem;
                width: 10%;

                transform: translate(24%, -248%);

            }

            //global
            40% {
                right: -4%;
                width: 17%;
                transform: translate(-3%, -39%);
                bottom: 0%;
            }

            //inr
            60% {
                right: 12%;
                width: 16%;
                transform: translate(-3%, -107%);
                bottom: 2%;
            }


            //ellips
            80% {

                width: 13%;
                bottom: -8%;
                right: 12%;
                transform: translate(-0%, -0%);
            }


            //vchain
            100% {
                bottom: 2%;
                transform: translate(-111%, -66%);
                width: 10%;
                right: 12%;
            }

        }


        @keyframes inr {
            //inr

            0% {
                bottom: 2%;
                right: 12%;
                width: 16%;
                transform: translate(-3%, -107%);
            }


            //elipse 
            20% {

                width: 13%;
                bottom: -8%;
                right: 12%;
                transform: translate(-0%, -0%);
            }


            //ered

            40% {
                bottom: 2%;
                right: 0rem;
                width: 10%;
                transform: translate(24%, -248%);
            }

            //vchain

            60% {
                bottom: 2%;
                transform: translate(-111%, -66%);
                width: 10%;
                right: 12%;
            }

            //global
            80% {
                bottom: 0%;
                right: -4%;
                width: 17%;
                transform: translate(-3%, -39%);

            }

            //inr

            100% {
                bottom: 2%;
                right: 12%;
                width: 16%;
                transform: translate(-3%, -107%);
            }

        }

        @keyframes global {

            //global

            0% {
                right: -4%;
                width: 17%;
                transform: translate(-3%, -39%);
                bottom: 0%;
                transition: all 0.1s ease-in;
            }

            //vchain

            20% {
                bottom: 2%;
                transform: translate(-111%, -66%);
                width: 10%;
                right: 12%;
            }

            //elipse
            40% {

                width: 13%;
                bottom: -8%;
                right: 12%;
                transform: translate(-0%, -0%);
            }

            //ered
            60% {

                bottom: 2%;
                right: 0rem;
                width: 10%;

                transform: translate(24%, -248%);

            }

            //inr
            80% {
                right: 12%;
                width: 16%;
                transform: translate(-3%, -107%);
                bottom: 2%;
                transition: all 0.1s ease-in;

            }

            100% {
                right: -4%;
                width: 17%;
                transform: translate(-3%, -39%);
                bottom: 0%;
                transition: all 0.1s ease-in;
            }
        }


        h1 {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 85px;
            line-height: 104px;
            display: flex;
            align-items: center;
            margin-bottom: 0;
            color: #FFFFFF;
        }

        h5 {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-size: 30px;
            line-height: 37px;
            display: flex;
            align-items: center;
            margin-top: 1.5rem;
            color: #FFFFFF;
        }
    }
}
.main-nvest{
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
scroll-behavior: smooth;
    width: 100%;

    height: 100vh;
}
.handle-main-nvest{
    height: 100vh;
    scroll-snap-align: start;
    scroll-snap-stop: always;
}
.HomePage-nvest {
   // display: flex;
    align-items: center;
    height: 100vh;
    width: 100%;


    .Left-side {
        border-right: 0.5px solid rgba(229, 229, 229, 0.7019607843);
        width: 348px;

        height: 100%;
        position: relative;
    }

    .list-story {}

    .Right-side {
        width: calc(100% - 348px);
        height: 100vh;
        position: relative;
        overflow: hidden;
     
    }
}
.sub-container{
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    scroll-behavior: smooth;
    width: 100%;
    display: flex;
    position: relative;
    height: 100vh;

}

.outer-story {
    position: relative;
    height: 100vh;
    scroll-snap-align: start;
    position: relative;
    scroll-snap-stop: always;
    scroll-snap-align: start;
    background-size: cover;
    background-position: center;
    transition: .2s ease;
 
}

.story-back {
    height: 100%;
    width: 100%;
    background-color: #0000008a;
    position: absolute;
    position: absolute;
    top: 0;
}

.roadMap{
    height: 100%;
    background-color: #FFFFFF
}

.ourstory {
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    position: relative;
    width: 100%;
    padding-top: 1rem;

    display: flex;
    flex-direction: column;



    .fixed-story {
        position: absolute;
        right: 0%;
        top: 50%;
        -webkit-transform: translate(0%, -50%) !important;
        transform: translate(0%, -50%) !important;
        width: 667px;
        height: 235px;
        background: #100F0D;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .fixed-story1 {
        position: absolute;
        right: 0%;
        top: 50%;
        -webkit-transform: translate(0%, -50%) !important;
        transform: translate(0%, -50%) !important;
        width: 667px;
        height: 235px;
        background: #100F0D;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .data-box{
        float: right;
        width: 667px;
        height: 235px;
        background: #100F0D;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: space-between;
        padding: 1.5rem;
    }

    .timeline-box{
        margin-top: -5px;
        display: flex;
        flex-direction: column;
        height: 20vh;
        width: calc(100vw - 348px);

        .events{
            height: 6px !important;
        }

        .enabled{
            background-color: #FFFFFF !important;
        }

        .past, .future{
            span{
                height: 14px !important;
                width: 14px !important;
                background-color: white !important;
                border: 3.5px solid white !important;
                bottom: -3px !important;
            }
        }

        .present{
            bottom: -3px !important;
            span{
                height: 14px !important;
                width: 14px !important;
                background-color: rgb(24, 37, 66) !important;
                border: 3.5px solid white !important;
                bottom: -3px !important;
            }
        }
    }

    h1 {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 34px;
        display: flex;
        align-items: center;
        margin-bottom: 0;

        color: #FFFFFF;
    }

    h3 {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 45px;
        line-height: 55px;
        display: flex;
        align-items: center;

        color: #FFFFFF;
        margin-bottom: 0;
    }

}

.react-parallax-content {
    border-left: 0.5px solid #E5E5E5;
}



.road-map-content {
    display: flex;
    justify-content: space-between;
    padding: 2rem 128px 2rem 20px;
    

    h1 {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 37px;
        display: flex;
        align-items: center;
        color: #100F0D;
        margin-bottom: 0;
    }

    .date-raodmap {
        display: flex;
        align-items: center;

        h1 {}

        img {
            width: 6.88px !important;
            height: 10px !important;

        }

        h2 {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            display: flex;
            align-items: center;
            margin-left: 10px;
            margin-right: 10px;
            color: #100F0D;
            margin-bottom: 0;
        }
    }
}

.main-section-table-custom {
    width: 100%;

}

.cells-body-top {
    border: 1px solid #E5E5E5;
    width: 100%;
    height: 73px;

}

.custom-scroll-scroll {
    position: absolute;
    width: 1px;
    top: 6rem;
    right: 0;
}

.right-side-content-section {
    width: calc(100% - 350px);
    overflow: scroll;

    .object-table1 {
        display: grid;
        grid-auto-flow: column;
    }

    .cells-body-one {
        border: 0.5px solid #E5E5E5;
        width: 15rem;
        height: 94px;
        display: table-cell;
    }

    .cells-header {
        border: 0.5px solid #E5E5E5;
        width: 15rem;
        height: 73px;
        border-bottom: 0px solid;
        display: flex;
        align-items: center;
        justify-content: center;

        h4 {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            margin-bottom: 0;
            line-height: 15px;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center1;
            color: #100F0D;
        }
    }
}

.body-roadmap {
    width: 350px;
}

.roadMap {
    .roadMap {
        width: 100%;
        position: relative;
        height: 100%;
        border-left: 1px solid #EFEFEF;
    }
    .table-road {
        height: 70%;
        width: 70%;
        display: flex;


        .body-roadmap {
            .Main-body-cells {
                display: flex;
                width: 100%;
            }

            // align-items: center;


            .cells-body {
                border: 0.5px solid #E5E5E5;
                padding-left: 20px;
                display: flex;
                align-items: center;
                height: 94px;
                border-left: 0;
                width: 100%;

            }

            img {
                width: 23px !important;
                height: 23px !important;
                margin-right: 10px;

            }

            h1 {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                display: flex;
                align-items: center;
                margin-bottom: 0px;
                color: #100F0D;
            }
        }

        .header {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 73px;
            border-bottom: 0.5px solid #E5E5E5;
            border-top: 0.5px solid #E5E5E5;
            width: 100%;

            .Main-header-cells {
                display: flex;
                width: 100%;

                height: 100%;

            }

            .cells-header-one {
                border-right: 0.5px solid #E5E5E5;
                width: 22%;
                border-top: 0.5px solid #E5E5E5;
            }

            .cells-header {
                border: 0.5px solid #E5E5E5;
                width: 6.5%;
                border-bottom: 0px solid;
                display: flex;
                align-items: center;
                justify-content: center;

                h4 {
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    margin-bottom: 0;
                    line-height: 15px;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    justify-content: center1;
                    color: #100F0D;
                }
            }
        }
    }
}




@media only screen and (max-width: 991.9px) {
    .HomePage-nvest {
        display: none !important;
    }
  
    .Staticlogo {
        left: 7%;
        width: 189px;
  display: block !important;
    }

.meta-econocy {
    height: 100vh;

    padding-left: 8% !important;
  
}

.bottom-content{
    font-size:  22px !important;
    span{
        font-size:  22px !important;   
    }
}
    .meta-econocy {
        .content {
            .ered{
                display: none;
            }
            .vchain{
                display: none;
            }
            .elipse{
                display: none;
            }
            .inr{
                display: none;
            }
            .global{
                display: none;
            }
            h1 {
             
                font-weight: 500;
                font-size: 34px !important;
                line-height: 53px !important;
            

            }
            h5 {
 
                font-size: 22px !important;
                line-height: 35px !important;
            
            }
        }
    }
}


.fade-in-down {
    animation: fade-in-down 2s ease;
  }
  @keyframes fade-in-down {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }