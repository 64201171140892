.rotatetranfrom {
  transform: rotate(180deg);
}

.titleMain {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
  align-items: center;
  padding-bottom: 1rem;
  padding-top: 3rem;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: scale(1.01);
    transition: all 0.3s ease;
  }

  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 35px;
    line-height: 43px;
    margin-bottom: 0px;

    color: #100F0D;
  }

  .inner {
    display: flex;
    align-items: flex-start;

    img {
      margin-right: 15px;
    }
  }
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  transition: all 0.3s ease;
  margin-top: 3rem;

  .tileName {
    font-style: normal;
    transition: all 0.3s ease;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    color: #100F0D;
    width: 20%;

    &:nth-child(1) {
      width: 25%;
    }

    &:nth-child(2) {
      width: 25%;
    }

    &:nth-child(5) {
      width: 10%;
    }
  }
}

.sectoinshowtoggle {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 138px;
  margin-bottom: 1rem;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 15px;
  transition: all 0.3s ease;
  cursor: pointer;
  transition: all 0.3s ease;

  // padding: 1.5rem;
  &:hover {
    transform: scale(1.01);
  }

  h6 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    color: #100F0D;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 27px;
    /* or 180% */
    margin-bottom: 0;
    display: flex;
    padding-right: 4rem;
    align-items: center;
    color: #100F0D;
  }

  h5 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    color: #100F0D;
  }

  label {
    margin-bottom: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 42px;
    display: flex;
    align-items: center;
    text-align: center;
    border-radius: 35px;
    background: linear-gradient(341.13deg, #5E3199 261.43%, #5E3199 342.4%);
    width: 126px;
    color: #ffffff;
    justify-content: center;

    &:hover {
      transform: scale(1.1);
    }
  }

  .contentItem {
    width: 20%;

    .status {
      color: #100F0D;
      font-family: Montserrat;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-decoration-line: underline;
      margin-top: 2rem;
      margin-bottom: 4px;
      display: none;
    }

    .inner {
      display: flex;
      align-items: center;

      img {
        margin-right: 12px;
      }
    }

    &:nth-child(1) {
      padding-left: 1.3%;
      width: 25%;
    }

    &:nth-child(2) {
      width: 25%;
    }

    &:nth-child(5) {
      width: 10%;
      padding-right: 1%;
    }
  }
}

.bngh {
  transition: all 0.9s ease;
}

.HoldingPage {
  max-width: 1300px;
  margin: auto;
  padding-bottom: 0rem;
  padding-top: 3rem;
  transition: all 0.3s ease;

  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    line-height: 42px;
    /* or 69% */

    display: flex;
    align-items: center;
    margin-top: 3rem;
    margin-bottom: 0rem;

    display: flex;
    align-items: center;
    margin-top: 3rem;
    margin-bottom: 0rem;
    justify-content: center;
    background: linear-gradient(180deg, #B580F2 0%, #5E3199 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
}

.titleMainCustom {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
  align-items: center;
  padding-bottom: 3rem !important;
  padding-top: 3rem;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.01);
    transition: all 0.3s ease;
  }

  &:nth-child(4) {
    border: 0px solid
  }
}

.topheadearhoilder {
  display: block;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  p {
    color: #100F0D;
    text-align: center;
    font-family: Montserrat;
    font-size: 27px;
    font-style: normal;
    font-weight: 300;
    line-height: 35px;
    /* 129.63% */
    cursor: pointer;

    &:hover {
      transform: scale(1.0.1);
    }
  }
}

.porprotfoilo {
  margin-top: 30px;
  color: #FFF;
  text-align: center;
  font-family: Montserrat;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 35px;
  background: linear-gradient(341.13deg, #5E3199 261.43%, #5E3199 342.4%);
  width: 250px;
  height: 49px;
  cursor: pointer;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    transform: scale(1.01);
  }
}

@media screen and (min-width: 300px) and (max-width: 900px) {
  .HoldingPage {
    padding-left: 8%;
    padding-right: 8%;
    padding-bottom: 0px;
  }

  .porprotfoilo {
    display: none;
  }

  .topheadearhoilder {

    justify-content: center;
    width: 100%;

    p {
      display: none;
    }
  }

  .sectoinshowtoggle {
    cursor: pointer;
    display: block;
    align-items: center;
    justify-content: space-between;
    height: auto;
    margin-bottom: 1rem;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 15px;
    position: relative;
    cursor: pointer;

    padding: 1.3rem;
    padding-top: 34px;
    padding-bottom: 41px;
  }

  .sectoinshowtoggle .contentItem .status {

    display: block;
  }

  .title {
    display: none;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    margin-top: 3rem;
  }

  .sectoinshowtoggle .contentItem:nth-child(1) {
    padding-left: 0%;
    width: 30%;
    width: 100%;
  }

  .sectoinshowtoggle .contentItem .inner {
    display: block;
    align-items: center;
  }

  .sectoinshowtoggle .contentItem .inner img {
    margin-right: 0;
    height: 52px;
    margin-bottom: 1rem;
  }

  .sectoinshowtoggle .contentItem {
    width: 100%;
  }

  .sectoinshowtoggle .contentItem:nth-child(2) {
    width: 100%;
  }

  .sectoinshowtoggle label {
    margin-bottom: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 35px;
    display: flex;
    align-items: center;
    text-align: center;
    border-radius: 35px;
    background: linear-gradient(341.13deg, #5E3199 261.43%, #5E3199 342.4%);
    width: 105px;
    color: #ffffff;
    justify-content: center;
    position: absolute;
    right: 1rem;
    top: 36px;
  }

  .sectoinshowtoggle p {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 27px;
    margin-bottom: 0;
    display: flex;
    padding-right: 0;
    align-items: center;
    color: #100F0D;
  }

  .sectoinshowtoggle h6 {
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 22px;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    color: #100F0D;
  }

  .HoldingPage h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    line-height: 79px;
    display: flex;
    align-items: center;
    margin-top: 3rem;
    margin-bottom: 0rem;
    display: flex;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 0rem;
    background: linear-gradient(341.13deg, #5E3199 261.43%, #5E3199 342.4%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  .titleMain {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 1px solid #e5e5e5;
    align-items: center;
    padding-bottom: 1rem;
    transition: all 0.3s ease;
    padding-top: 0;

    &:hover {
      transform: scale(1.01);
      transition: all 0.3s ease;
    }
  }

  .titleMainCustom {
    padding-top: 2rem !important;
  }

  .titleMain h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 43px;
    margin-bottom: 0px;
    color: #100F0D;
  }
}