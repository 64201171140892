.founderpage{
    height: 100%;
    h5{
        display: none;
    }
    .innerfounder{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;height: 50%;
        position: relative;
        padding-left: 8%;
    }
    h1{
        color: #100F0D;
font-family: Montserrat;
font-size: 40px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-bottom: 0;
    }
    p{
        color: #100F0D;
font-family: Montserrat;
font-size: 23px;
font-style: normal;
font-weight: 300;
line-height: normal;
    }
    .imgSectionimg{
        display: flex;
        align-items: start;
        .img{
            margin-right: 35px;
            cursor: pointer;
            &:hover{
                transform: scale(1.03);
            }
        }
    }
    .shoo{
        position: absolute;
        bottom: 0;
        right: 5%;
        height: 43vh;
    }
    label{
        color: #FFF;
text-align: center;
font-family: Montserrat;
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: normal;
width: 269px;
height: 48px;
width: 269px;
border-radius: 35px;
background: linear-gradient(341.13deg, #5E3199 261.43%, #5E3199  342.4%);
align-items: center;
justify-content: center;
cursor: pointer;
display: flex;
height: 48px;
&:hover{
    transform: scale(1.01);
}
    }
}

@media screen and (min-width: 300px) and (max-width: 900px) {


    .founderpage{
        height: auto;
        padding-right: 8%;
        padding-left: 8%;
        .innerfounder{
            height: auto;
            align-items: center;
            padding-top: 2rem;
            padding-right: 8%;
            border-radius: 35px;
border: 0.5px solid #EDEDED;
background: #F3F4F5;
margin-top: 2rem;
        }
.shoo1{
    height: 20rem;
}
        .shoo {
            position: static;
            bottom: 0;
            right: 5%;
            height: 20rem;
            margin-top: 2rem;
        }
        p {
            color: #100F0D;
            font-family: Montserrat;
            font-size: 18px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
        }
        label {
            color: #FFF;
            text-align: center;
            font-family: Montserrat;
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            width: 269px;
            height: 48px;
            width: 241px;
            border-radius: 35px;
            background: linear-gradient(341.13deg, #5E3199 261.43%, #5E3199  342.4%);
            align-items: center;
            justify-content: center;
            cursor: pointer;
            display: flex;
            height: 46px;
            margin-top: 1.5rem;
            display: none;
        }
        h5{
                font-family: "Montserrat";
                font-style: normal;
              font-weight: 500;
              line-height: 31px;
                /* or 69% */
            font-size: 30px;
                display: flex;
                align-items: center;
                margin-top: 3rem;
                margin-bottom: 0rem;
            
                display: flex;
                align-items: center;
                margin-top: 0rem;
                margin-bottom: 0rem;
                justify-content: center;
            
                background: linear-gradient(180deg,#B580F2  0%,  #5E3199  100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
              
        }
        .imgSectionimg {
            display: none;
            align-items: start;
        }
        h1 {
            color: #100F0D;
            font-family: Montserrat;
            font-size: 27px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-bottom: 0;
            text-align: center;
        }


}

}